import SmallLayout from "~/components/SmallLayout";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";

import { useState } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import FourthStep from "./FourthStep";
import SixthStep from "./SixthStep";
import FifthStep from "./FifthStep";
import SeventhStep from "./SeventhStep";

const cx = classNames.bind(styles);

export default function CleaningSteps() {
  const [info, setInfo] = useState({
    provider: "",
    package: "",
    type: "",
    price: "",
    paymentType: "",
    discount: 0,
    total: "",
    date: "",
    time: "",
    numberOfDevices: "",
    notePros: "",
    contact: "",
    purposeInternet: [],
    purposeTV: [],
  });

  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <FirstStep info={info} setInfo={setInfo} />
          <SecondStep internet={info} setInternet={setInfo} />
          <ThirdStep internet={info} setInternet={setInfo} />
          <FourthStep info={info} setInfo={setInfo} />
          <FifthStep info={info} setInfo={setInfo} />
          <SixthStep info={info} setInfo={setInfo} />
          <SeventhStep info={info} setInfo={setInfo} />
        </div>
      </div>
    </SmallLayout>
  );
}
