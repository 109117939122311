import classNames from "classnames/bind";
import styles from "./RentalManager.module.scss";
import Sliders from "~/components/Sliders";
import It from "~/components/TextAndImage/It";
import Partner from "~/components/TextAndImage/Ti";
import AllText from "~/components/AllText";
import FourPads from "~/components/Pads/FourPads";
import icons from "~/assets/icons";
import Button from "~/components/Button";
import CardsManager from "~/components/TextAndImage/CardsManager";
import FourColumns from "~/components/Columns/FourColumns";
import {
  ManagerColumnFour,
  ManagerColumnOne,
  ManagerColumnThree,
  ManagerColumnTwo,
} from "~/components/Icons";
import Three from "~/components/TextAndImage/Three";
import images from "~/assets/images";

const cx = classNames.bind(styles);

function RentalManager() {
  return (
    <div className={cx("main")}>
      <AllText item={itemSolutions} center />
      <CardsManager item={itemPartner} />
      <FourColumns item={itemColumns} />
      <Three item={itemPartner2} />
      <It item={itemCustomer2} />
      <FourPads
        title="Cư dân có thể giải quyết mọi thứ ở một nơi"
        data={padsData}
      />

      <Sliders item={itemSliders} />
    </div>
  );
}

export default RentalManager;

const itemColumns = {
  title: "Tìm thấy cách tốt hơn để chuyển nhà",
  text: "Chúng tôi đảm nhận mọi chi tiết thay bạn",
  intros: [
    {
      id: 1,
      title: "Tất cả các nhiệm vụ chuyển nhà của bạn ở một nơi",
      subtile1:
        "Từ đặt xe tải chở đồ đến dọn dẹp nhà cửa: luôn cập nhật mọi thứ bạn cần làm trong một ứng dụng.",
      subtile2: "Nhận các mẹo chuyển nhà hữu ích và lời nhắc kịp thời.",
      icon: <ManagerColumnOne />,
    },
    {
      id: 2,
      title: "Cá nhân hóa, người hỗ trợ riêng",
      subtile1:
        "Vâng, bạn có thể nói chuyện với một con người! Trình cập nhật kết hợp dịch vụ trực tiếp tốt nhất với công nghệ trực quan.",
      subtile2:
        "Đội ngũ giàu kinh nghiệm của chúng tôi luôn sẵn sàng hỗ trợ bạn khi bạn cần.",
      icon: <ManagerColumnTwo />,
    },
    {
      id: 3,
      title: "Tìm kiếm, so sánh và tự tin mua sắm",
      subtile1:
        "So sánh các lựa chọn từ các nhà cung cấp internet, TV, vật liệu đóng gói và thậm chí cả các bên chuyển nhà hàng đầu của quốc gia.",
      subtile2:
        "Thực hiện lựa chọn phù hợp với bạn mà không cần phải rời khỏi ứng dụng.",
      icon: <ManagerColumnThree />,
    },
    {
      id: 4,
      title: "Tìm kiếm, so sánh và tự tin mua sắm",
      subtile1:
        "So sánh các lựa chọn từ các nhà cung cấp internet, TV, vật liệu đóng gói và thậm chí cả các bên chuyển nhà hàng đầu của quốc gia.",
      subtile2:
        "Thực hiện lựa chọn phù hợp với bạn mà không cần phải rời khỏi ứng dụng.",
      icon: <ManagerColumnFour />,
    },
  ],
};

const itemSolutions = {
  pre: "",
  title: "Đảm bảo quá trình chuyển vào suôn sẻ",
  text: "Hàng nghìn cộng đồng tin tưởng Trình cập nhật sẽ giúp cư dân di chuyển dễ dàng và đáp ứng mọi yêu cầu mà không cần phải qua lại.",
  action: <Button primary>Hợp tác với chúng tôi</Button>,
};

const itemCustomer2 = {
  pre: "DỊCH VỤ CẢI THIỆN",
  title:
    "3 trong số 4 cư dân sử dụng Trình cập nhật cảm thấy kiểm soát tốt hơn việc di chuyển sắp tới của họ *",
  text: "Cư dân được thông báo về các nhiệm vụ họ cần hoàn thành và công nghệ trực quan của chúng tôi giúp việc đánh dấu các mục trong danh sách của họ trở nên dễ dàng hơn bao giờ hết.",
  imageExtra: images.frontpageCleaning,
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/inverted-smiling-couple.png",
};

const itemPartner = {
  pre: "ĐỐI TÁC ĐA GIA ĐÌNH",
  title: "Di chuyển vào được thực hiện ngay",
  text: "Tự động hóa việc chuyển đến của cư dân trong tương lai, giúp cư dân và nhân viên đi đúng hướng. Tham gia hàng nghìn cộng đồng tận dụng Trình cập nhật để định cấu hình các yêu cầu chuyển đến, duy trì sự tập trung cao độ của các nhóm cho thuê và thúc đẩy doanh thu phụ trợ.",
};

const itemPartner2 = {
  pre: "CÁC NHÀ CUNG CẤP DỊCH VỤ",
  title: "Tham gia với động lực vào đúng thời điểm",
  text: "Tận dụng Trình cập nhật để tiếp cận hàng triệu người di chuyển một cách thông minh tại tất cả các điểm trong vòng đời di chuyển. Chúng tôi cung cấp các giải pháp tích hợp, nhắm mục tiêu và kịp thời để cung cấp khách hàng chứ không phải khách hàng tiềm năng.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/multifamily/property-manager.png",
};

export const itemSliders = {
  title: "Thông tin chi tiết hỗ trợ doanh nghiệp của bạn",
  text: "Khám phá những ý tưởng và công nghệ định hình ngành công nghiệp của chúng tôi.",

  list: [
    {
      id: 1,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1668712945/Copy-of-Blog_Hero_Template_1000x667-2/Copy-of-Blog_Hero_Template_1000x667-2.png?_i=AA",
      title:
        "7 cách trải nghiệm Uhomes nâng cao sẽ giúp cuộc sống của cư dân nhiều gia đình và các nhóm tại chỗ dễ dàng hơn",
      description:
        "Khi ngày chuyển nhà cuối cùng cũng đến, bạn có thể thở phào nhẹ nhõm. Những ngày đóng gói và lên kế hoạch đã qua, và bây giờ tất cả những gì còn lại là ổn định cuộc sống tại ngôi nhà mới của bạn. Tuy nhiên, ngày chuyển nhà là một nỗ lực hợp tác — cho dù bạn đang làm việc với những người chuyển nhà chuyên nghiệp hay nhờ sự giúp đỡ của bạn bè, bạn nên cân nhắc xem các nghi thức chuyển nhà giúp quá trình này trở nên dễ dàng hơn như thế nào đối với mọi người tham gia vào ngày trọng đại.",
      introDetails:
        "Dưới đây là chín bước bạn có thể thực hiện trong quá trình chuyển nhà để khiến một ngày trôi qua nhanh chóng và giữ cho mọi người có tinh thần tốt.",
      details: [
        {
          id: 1,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Khi bạn thức dậy vào ngày chuyển nhà, đồ đạc của bạn đã được đóng gói, dán nhãn và sẵn sàng để chất lên xe tải. Bạn có thể cần đóng gói một số đồ vệ sinh cá nhân và khăn trải giường vào buổi sáng ngày chuyển đi, nhưng phần lớn việc đóng gói nên được hoàn thành trước. Điều này cho phép máy động lực của bạn đến và làm việc ngay lập tức.",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 2,
          title: "Đóng gói gọn gàng, dán nhãn rõ ràng",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 3,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999723/shutterstock_1170001924/shutterstock_1170001924.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 4,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 5,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 6,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 7,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999373/shutterstock_1557782294/shutterstock_1557782294.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 8,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 9,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
      ],
    },
    {
      id: 2,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/w_1024,h_855/f_auto,q_auto/v1668715011/Welcome-Letter-Example/Welcome-Letter-Example.png?_i=AA",
      title: "How to Save on Internet, Cable, and Phone",
      description:
        "Moving is expensive and knowing how to save on your internet, cable, and phone bills can really help balance out that spending. Curious about the best ways to save? Turns out, it’s not such a challenge once you know how. Here are 11 of our tried-and-true techniques for how you can save on internet, cable, and phone while still getting the most out of your monthly payments.",
      introDetails:
        "Dưới đây là chín bước bạn có thể thực hiện trong quá trình chuyển nhà để khiến một ngày trôi qua nhanh chóng và giữ cho mọi người có tinh thần tốt.",
      details: [
        {
          id: 1,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Khi bạn thức dậy vào ngày chuyển nhà, đồ đạc của bạn đã được đóng gói, dán nhãn và sẵn sàng để chất lên xe tải. Bạn có thể cần đóng gói một số đồ vệ sinh cá nhân và khăn trải giường vào buổi sáng ngày chuyển đi, nhưng phần lớn việc đóng gói nên được hoàn thành trước. Điều này cho phép máy động lực của bạn đến và làm việc ngay lập tức.",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 2,
          title: "Đóng gói gọn gàng, dán nhãn rõ ràng",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 3,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999723/shutterstock_1170001924/shutterstock_1170001924.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 4,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 5,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 6,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 7,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999373/shutterstock_1557782294/shutterstock_1557782294.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 8,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 9,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
      ],
    },
    {
      id: 3,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1652737288/Updater-Resident-Onboarding/Updater-Resident-Onboarding.png?_i=AA",
      title: "Moving While Expecting Kids? Be Sure to Set up TV and Internet",
      description:
        "Of all the things you deal with as a new parent during a household move, your belongings may be the most obvious. But what about services? When it comes to TV and internet, it pays to plan ahead and make sure you have service for every step of your journey. Here’s how to ensure you’re prepared to tackle parenthood, especially in your new home.",
    },
  ],
};

export const padsData = [
  {
    id: 1,
    name: "Internet & truyền hình",
    description:
      "Cư dân có thể so sánh, mua sắm và đặt mua các gói từ tất cả các nhà cung cấp hàng đầu và chúng tôi sẽ nêu bật nhà cung cấp mà bạn thích.",
    icon: icons.internet,
    className: "item1",
  },
  {
    id: 2,
    name: "Tiện ích",
    description:
      "Cư dân có thể xem tất cả các nhà cung cấp điện, ga và nước tại cộng đồng của bạn và tải lên bằng chứng về dịch vụ. Không cần cái đó? Lật một công tắc để tắt nó đi.",
    icon: icons.moving,
    className: "item2",
  },
  {
    id: 3,
    name: "Bảo hiểm",
    description:
      "Cư dân có thể tìm và mua các chính sách đáp ứng các yêu cầu của cộng đồng của bạn và dễ dàng chia sẻ bằng chứng về bảo hiểm trước ngày chuyển đi.",
    icon: icons.cleaning,
    className: "item3",
  },
  {
    id: 4,
    name: "Di chuyển",
    description:
      "Cư dân có thể so sánh và đặt trước các phương án di chuyển phù hợp với mọi ngân sách với mức giá đảm bảo, thời gian thực hiện ngắn và đặt chỗ ngay lập tức.",
    icon: icons.aircon,
    className: "item4",
  },
];
