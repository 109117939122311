import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames/bind";
import styles from "./Checkboxs.module.scss";
const cx = classNames.bind(styles);

function MyCheckbox({ data, item, plus, minus }) {
  const [checked, onChange] = useState(data.join("").includes(item.value));

  function onCheckmarkPress() {
    onChange(!checked);
    !checked ? plus(item.value) : minus(item.value);
  }
  return (
    <div onClick={onCheckmarkPress}>
      <div className={cx("checkbox-item")}>
        <input
          className={cx("myinput")}
          type="checkbox"
          checked={checked}
          onChange={onCheckmarkPress}
        />
        <div
          className={cx("mydisplay")}
          style={{
            backgroundColor: checked ? "#ff7800" : "white",
            border: `2px solid ${checked ? "#ff7800" : "darkgray"}`,
          }}
        >
          {checked && (
            <div className={cx("tick")}>
              <FontAwesomeIcon icon={faCheck} color="white" />
            </div>
          )}
        </div>
        <span className={cx("title")}>{item.title || item.name}</span>
      </div>
    </div>
  );
}

const Checkboxs = function ({ data, list, plus, minus }) {
  return (
    <>
      {list.map((item) => (
        <MyCheckbox
          key={item.id}
          item={item}
          plus={plus}
          minus={minus}
          data={data}
        />
      ))}
    </>
  );
};
export default Checkboxs;
