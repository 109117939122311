import { useRef, useEffect } from "react";
import Radio from "~/components/steps/utils/Radio";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";

const cx = classNames.bind(styles);

const ThirdStep = ({ info, setInfo }) => {
  const handleClickRadio = (value) => {
    setInfo((prev) => ({
      ...prev,
      time: value,
    }));
  };
  const thirdRef = useRef(null);

  useEffect(() => {
    thirdRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <div className={cx("section")} ref={thirdRef}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Khi nào là thời điểm tốt?</h2>
      </div>
      <div className={cx("right")}>
        <Radio
          divide
          list={numberOfDevices}
          onClick={handleClickRadio}
          value={info.time}
        />
      </div>
    </div>
  );
};
export default ThirdStep;

const numberOfDevices = [
  {
    id: 1,
    name: "7 giờ sáng",
    value: "7 giờ sáng",
  },
  {
    id: 2,
    name: "8 giờ sáng",
    value: "8 giờ sáng",
  },
  {
    id: 3,
    name: "9 giờ sáng",
    value: "9 giờ sáng",
  },
  {
    id: 3,
    name: "10 giờ sáng",
    value: "10 giờ sáng",
  },
  {
    id: 4,
    name: "13:00",
    value: "13:00",
  },
  {
    id: 5,
    name: "14:00",
    value: "14:00",
  },
  {
    id: 5,
    name: "3 giờ chiều",
    value: "3 giờ chiều",
  },
  {
    id: 5,
    name: "4 giờ chiều",
    value: "4 giờ chiều",
  },
  {
    id: 5,
    name: "5 giờ chiều",
    value: "5 giờ chiều",
  },
];
