import { SET_REFERRAL_CODE } from "../constants";

const initialState = {
  referralCode: null,
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFERRAL_CODE:
      return {
        ...state,
        referralCode: action.payload,
      };
    default:
      return state;
  }
};

export default referralReducer;
