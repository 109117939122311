import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import ThirdStep from "./ThirdStep";
import SeventhStep from "./SeventhStep";

import SmallLayout from "~/components/SmallLayout";

const cx = classNames.bind(styles);

function InternetSteps() {
  const [info, setInfo] = useState({
    address: "",
    type: "",
    numberOfDevices: "",
    purposeOfInternet: [],
    purposeOfTV: [],
    phoneNumber: "",
    note: "",
  });

  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <h2 className={cx("heading")}>Lắp đặt Internet & TV</h2>
          <FirstStep info={info} setInfo={setInfo} />
          {info.address && <SecondStep info={info} setInfo={setInfo} />}
          {info.type && <ThirdStep info={info} setInfo={setInfo} />}
          {info.numberOfDevices && <FourthStep info={info} setInfo={setInfo} />}

          {info.type === "combo" && <FifthStep info={info} setInfo={setInfo} />}

          {info.numberOfDevices && <SixthStep info={info} setInfo={setInfo} />}
          {info.numberOfDevices && (
            <SeventhStep info={info} setInfo={setInfo} />
          )}
        </div>
      </div>
    </SmallLayout>
  );
}

export default InternetSteps;
