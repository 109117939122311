import React from "react";

function CardTypeFour({ item }) {
  return (
    <div
      className="svg-polyfill"
      style={{ transform: "scaleX(-1)", height: "100%", width: "100%" }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 332 262"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="171.953"
          y="205.538"
          width="120.309"
          height="181.931"
          rx="24"
          transform="rotate(158.346 171.953 205.538)"
          fill="#84e2b7"
          className="blue-04"
        ></rect>
        <rect
          x="245.618"
          y="221.807"
          width="143.997"
          height="217.752"
          rx="24"
          transform="rotate(168.044 245.618 221.807)"
          fill="#35cf88"
          className="blue"
        ></rect>
        <foreignObject
          className="svg-image-video"
          width="173"
          height="262"
          x="159"
        >
          <div
            className="svg-image-video-image"
            style={{
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              backgroundPosition: "50%",
              backgroundSize: "cover",
              backgroundImage: `url(${item.imageUrl})`,
            }}
          ></div>
        </foreignObject>
      </svg>
    </div>
  );
}

export default CardTypeFour;
