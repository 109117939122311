import classNames from "classnames/bind";
import styles from "./Review.module.scss";

const cx = classNames.bind(styles);

function Review({ item }) {
  return (
    <div className={cx("container")}>
      <h2 className={cx("pre")}>{item.pre}</h2>
      <h2 className={cx("title")}>{item.content}</h2>
      <p className={cx("subTitle")}>{item.author}</p>
      <p className={cx("subTitle")}>{item.place}</p>
    </div>
  );
}

export default Review;
