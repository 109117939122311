import classNames from "classnames/bind";
import Button from "~/components/Button";
import Card from "./Card";
import styles from "./DropMenu.module.scss";

import { useNavigate } from "react-router-dom";
const cx = classNames.bind(styles);

function DropMenu({ item, internet, onCloseModal }) {
  const navigate = useNavigate();
  return (
    <div className={cx("container")}>
      <div
        className={cx("left")}
        style={internet && { backgroundColor: "#f7f4f1" }}
      >
        <div className={cx("preHeading")}>{item.preLeft}</div>
        <h2 className={cx("title")}>{item.title}</h2>
        <p className={cx("text")}>{item.text} </p>
        <Button
          primary
          onClick={() => {
            onCloseModal(false);
            navigate(
              internet ? "/kinh-nghiem-chuyen-nha" : "/tin-tuc-can-biet"
            );
          }}
        >
          {item.button}
        </Button>
      </div>
      <div className={cx("right")}>
        <div className={cx("preHeadingRight")}>{item.preRight}</div>
        <div className={cx("cards")}>
          {item.list.map((item) => {
            const handleClickItem = () => {
              onCloseModal(false);
              navigate(item.link);
            };
            return (
              <Card
                key={item.id}
                item={item}
                handleClickItem={handleClickItem}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DropMenu;
