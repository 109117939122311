import {
  ADD_AIRCON_ITEM,
  ADD_AIRCON_QUANTITY_ITEM,
  MINUS_AIRCON_ITEM,
  MINUS_AIRCON_QUANTITY_ITEM,
  RESET_AIRCON,
  ADD_NOTE_ITEM_AIRCON,
  ADD_TOGGLE_AIRCON,
  AIRCON_DATE,
  AIRCON_TIME,
  AIRCON_TOTAL,
  AIRCON_NOTEPROS,
  AIRCON_CONTACT,
} from "../constants";

export const addAirconItem = (data) => (dispatch) => {
  dispatch({ payload: data, type: ADD_AIRCON_ITEM });
};

export const minusAirconItem = (data) => (dispatch) => {
  dispatch({ payload: data, type: MINUS_AIRCON_ITEM });
};

export const addAirconQuantityItem = (data) => (dispatch) => {
  dispatch({ payload: data, type: ADD_AIRCON_QUANTITY_ITEM });
};

export const minusAirconQuantityItem = (data) => (dispatch) => {
  dispatch({ payload: data, type: MINUS_AIRCON_QUANTITY_ITEM });
};

export const addNoteItemAircon = (data) => (dispatch) => {
  dispatch({ payload: data, type: ADD_NOTE_ITEM_AIRCON });
};

export const addToggleAircon = (data) => (dispatch) => {
  dispatch({ payload: data, type: ADD_TOGGLE_AIRCON });
};

export const airconDate = (data) => (dispatch) => {
  dispatch({ payload: data, type: AIRCON_DATE });
};

export const airconTime = (data) => (dispatch) => {
  dispatch({ payload: data, type: AIRCON_TIME });
};

export const airconTotal = (data) => (dispatch) => {
  dispatch({ payload: data, type: AIRCON_TOTAL });
};

export const airconNotePro = (data) => (dispatch) => {
  dispatch({ payload: data, type: AIRCON_NOTEPROS });
};

export const airconContact = (data) => (dispatch) => {
  dispatch({ payload: data, type: AIRCON_CONTACT });
};

export const resetAircon = () => (dispatch) => {
  dispatch({ type: RESET_AIRCON });
};
