import React from "react";
import classNames from "classnames/bind";
import styles from "./SlideUp.module.scss";
import Button from "~/components/Button";
import { Link } from "react-router-dom";
import routes from "~/config/routes";

const cx = classNames.bind(styles);

export default function SlideUp({ item }) {
  return (
    <Link to={routes.movingDetails}>
      <div className={cx("item")}>
        {/* <a href="https://openai.com" /> */}
        <img src={item.imageUrl} alt="google" />
        <div className={cx("item__overlay")}>
          <div className={cx("item__title")}>
            <h2 className={cx("item__title-first")}>{item.title}</h2>
            <p className={cx("item__title-second")}>
              Ngày 20 tháng 02 năm 2023
            </p>
          </div>

          <div className={cx("item__body")}>
            <p className={cx("item__title-third")}>{item.description} </p>
            <Button primary>Xem thêm</Button>
          </div>
        </div>
      </div>
    </Link>
  );
}
