import {
  RESET_CLEANOTHER,
  ADD_CLEANOTHER_ITEM,
  MINUS_CLEANOTHER_ITEM,
  ADD_CLEANOTHER_QUANTITY_ITEM,
  MINUS_CLEANOTHER_QUANTITY_ITEM,
  ADD_NOTE_ITEM_CLEANOTHER,
  ADD_TOGGLE_CLEANOTHER,
  CLEANOTHER_NOTEPROS,
  CLEANOTHER_CONTACT,
  CLEANOTHER_TOTAL,
  CLEANOTHER_DATE,
  CLEANOTHER_TIME,
} from "../constants";

const initialState = {
  items: [],
  date: "",
  time: "",
  notePros: "",
  total: "",
  discount: 0,
  contact: "",
};

export const cleanOther = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLEANOTHER_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case MINUS_CLEANOTHER_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case ADD_CLEANOTHER_QUANTITY_ITEM:
      const itemAdded = state.items.find((item) => item.id === action.payload);
      const newItemAdded = {
        ...itemAdded,
        quantity: itemAdded.quantity + 1,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload),
          newItemAdded,
        ],
      };

    case MINUS_CLEANOTHER_QUANTITY_ITEM:
      const item = state.items.find((item) => item.id === action.payload);
      const newItem = {
        ...item,
        quantity: item.quantity - 1,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload),
          newItem,
        ],
      };

    case ADD_NOTE_ITEM_CLEANOTHER:
      const itemNoteAdded = state.items.find(
        (item) => item.id === action.payload.id
      );
      const newNoteItemAdded = {
        ...itemNoteAdded,
        note: action.payload.note,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload.id),
          newNoteItemAdded,
        ],
      };

    case ADD_TOGGLE_CLEANOTHER:
      const itemToggleAdded = state.items.find(
        (item) => item.id === action.payload.id
      );
      const newToggleItemAdded = {
        ...itemToggleAdded,
        gas: action.payload.gas,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload.id),
          newToggleItemAdded,
        ],
      };

    case CLEANOTHER_NOTEPROS:
      return {
        ...state,
        notePros: action.payload,
      };
    case CLEANOTHER_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case CLEANOTHER_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case CLEANOTHER_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case CLEANOTHER_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case RESET_CLEANOTHER:
      return initialState;
    default:
      return state;
  }
};
