import classNames from "classnames/bind";
import styles from "./ModalMobile.module.scss";
import Sidebar from "../../../../layouts/components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../Button";
import { logout } from "../../../../redux/actions/auth";

const cx = classNames.bind(styles);

const ModalMobile = ({ children, modalShown, toggleModal }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser);

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div>
      {modalShown ? (
        <div
          className={cx("modal-backdrop")}
          onClick={() => {
            toggleModal(false);
          }}
        >
          <div
            className={cx("modal-content")}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {user && <Sidebar toggleModal={toggleModal} />}
            {children}
            <div className={cx("login-button")}>
              {!user && (
                <Button primary onClick={() => navigate("/login")}>
                  Đăng nhập
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ModalMobile;
