import React, { useState } from "react";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
export const GOOGLE_API_KEY = "AIzaSyA_fJzbQl3MbX95Y5P0hysfdiDVDf3ZMR4";

function MapSearch({ setValue }) {
  const [searchResult, setSearchResult] = useState("Result: none");

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      const name = place.name;
      const status = place.business_status;
      const formattedAddress = place.formatted_address;
      // console.log(place);
      console.log(`Name: ${name}`);
      console.log(`Business Status: ${status}`);
      console.log(`Formatted Address: ${formattedAddress}`);
      setValue(formattedAddress);
    } else {
      alert("Please enter text");
    }
  }

  if (!isLoaded) {
    return <div>Đang tải...</div>;
  }

  return (
    <Autocomplete
      componentRestrictions={{ country: ["vi"] }}
      onPlaceChanged={onPlaceChanged}
      onLoad={onLoad}
    >
      <input
        type="text"
        placeholder="Nhập địa điểm"
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `100%`,
          height: `32px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }}
      />
    </Autocomplete>
  );
}

export default MapSearch;
