import classNames from "classnames/bind";
import styles from "./CardTypeTwo.module.scss";

const cx = classNames.bind(styles);

function CardTypeTwo({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className={cx("right-image-container")}>
          <img
            alt=""
            className={cx("right-image")}
            src={item?.imageUrl || ""}
          />
        </div>

        <div className={cx("left-image")}>
          <img alt="" src={item?.imageExtra || ""} />
        </div>
        <div className={cx("space")}></div>
      </div>
    </div>
  );
}

export default CardTypeTwo;
