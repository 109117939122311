import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Radios from "~/components/Radios";

const cx = classNames.bind(styles);

const ThirdStep = ({ info, setInfo }) => {
  const handleClickRadio = (item) => {
    setInfo((prev) => ({
      ...prev,
      numberOfDevices: item.value,
    }));
  };

  return (
    <div className={cx("section")}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Số thiết bị sử dụng</h2>
      </div>
      <div className={cx("right")}>
        <Radios
          list={numberOfDevices}
          handleClickItem={handleClickRadio}
          value={info.numberOfDevices}
        />
      </div>
    </div>
  );
};
export default ThirdStep;

const numberOfDevices = [
  {
    id: 1,
    name: "1 -5",
    value: "1 - 5",
  },
  {
    id: 2,
    name: "6 - 11",
    value: "6 - 11",
  },
  {
    id: 3,
    name: "12+",
    value: "12+",
  },
];
