import React, { useState } from "react";

import Search from "~/components/LiveSearch/Search";
import { useNavigate } from "react-router-dom";
import Button from "~/components/Button/Button";

import SmallLayout from "~/components/SmallLayout";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Floor from "./Floor";

const cx = classNames.bind(styles);

function MovingSteps() {
  const navigate = useNavigate();

  const [info, setInfo] = useState({
    type: "",
    date: "",
    time: "",
    start: "",
    finish: "",
    note: "",
  });
  const [start, setStart] = useState(info.start);
  const [finish, setFinish] = useState(info.finish);

  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <h2>Yêu cầu báo giá chuyển nhà</h2>
          <div className={cx("section")}>
            <div className={cx("left")}>
              <h2 className={cx("title")}>
                Xin vui lòng cho biết địa chỉ bạn chuyển đi.
              </h2>
            </div>
            <div className={cx("right")}>
              <Search value={start} setValue={setStart} />
            </div>
            <Floor info={info} setInfo={setInfo} />
          </div>

          <div className={cx("section")}>
            <div className={cx("left")}>
              <h2 className={cx("title")}>
                Xin vui lòng cho biết địa chỉ bạn chuyển đến.
              </h2>
            </div>
            <div className={cx("right")}>
              <Search value={finish} setValue={setFinish} />
            </div>
            <Floor info={info} setInfo={setInfo} />
          </div>
          <div className={cx("button-wrapper")}>
            <Button onClick={() => navigate("/moving/size")} primary>
              Tiếp tục
            </Button>
          </div>
        </div>
      </div>
    </SmallLayout>
  );
}

export default MovingSteps;
