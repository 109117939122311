const images = {
  logo: require("~/assets/images/move.png"),
  noImage: require("~/assets/images/no-image.png"),
  avatar: require("~/assets/images/avatar-placeholder.svg").default,
  email: require("~/assets/images/email.svg").default,
  phone: require("~/assets/images/smartphone.svg").default,
  appstore: require("~/assets/images/appstore.svg").default,
  playstore: require("~/assets/images/playstore.svg").default,
  searchNothing: require("~/assets/images/searchNothing.svg").default,
  googleIcon: require("~/assets/images/google.svg").default,
  imageHolder: require("~/assets/images/image-placholder.png"),
  post: require("~/assets/images/post.svg").default,
  searchHome: require("~/assets/images/searchHome.png"),
  cash: require("~/assets/images/cash.png"),
  onTheGo: require("~/assets/images/onTheGo.png"),
  done: require("~/assets/images/done.svg").default,
  fpt: require("~/assets/images/fpt.svg").default,
  viettel: require("~/assets/images/viettel.png"),
  vnpt: require("~/assets/images/vnpt.png"),
  picture: require("~/assets/images/picture.png"),
  pin: require("~/assets/images/pin.png"),
  move: require("~/assets/images/moving.svg").default,
  frontpage: require("~/assets/images/frontpage-abstraction.svg").default,
  frontpageCleaning: require("~/assets/images/fontpage-cleaning.svg").default,

  internet: require("~/assets/images/internet.svg").default,
};

export default images;
