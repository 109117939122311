import classNames from "classnames/bind";
import styles from "./CardTypeManager.module.scss";

const cx = classNames.bind(styles);

function CardTypeManager({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className="visual-abstraction-inner">
          <img
            alt=""
            className="visual-abstraction-image"
            src="https://res.cloudinary.com/updater-marketing/image/upload/q_auto/updater-dot-com/multifamily/updater-pro-table.svg"
          />
          <div className={cx("extra-image")}>
            <img
              alt=""
              src="https://res.cloudinary.com/updater-marketing/image/upload/q_auto/updater-dot-com/multifamily/updater-pro-status.svg"
            />
          </div>
          <div
            className="visual-abstraction-abstraction-width"
            style={{ width: "50%" }}
          ></div>
        </div>
      </div>
      <div className={cx("space")}></div>
    </div>
  );
}

export default CardTypeManager;
