import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Radios from "~/components/Radios";

const cx = classNames.bind(styles);
const FourthStep = ({ info, setInfo }) => {
  const handleClickItem = (item) => {
    setInfo((prev) => ({ ...prev, type: item.value }));
  };

  return (
    <div className={cx("section")}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Bạn đã có dụng cụ vệ sinh chưa.</h2>
      </div>
      <div className={cx("right")}>
        <Radios
          value={info.type}
          list={list}
          handleClickItem={handleClickItem}
        />
      </div>
    </div>
  );
};
export default FourthStep;

const list = [
  {
    id: 1,
    title: "Vâng, tôi đã có",
    value: "yes",
  },
  {
    id: 2,
    title: "Tôi chưa có",
    value: "no",
  },
];
