import { useEffect, useState, useRef } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";

import Calendar from "react-calendar";

const cx = classNames.bind(styles);

const SecondStep = ({ info, setInfo }) => {
  const [value, onChange] = useState(info.date);
  const secondRef = useRef(null);

  useEffect(() => {
    setInfo((prev) => ({ ...prev, date: value }));
  }, [value, setInfo]);

  useEffect(() => {
    secondRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className={cx("section")} ref={secondRef}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Khi nào bạn có kế hoạch chuyển nhà?</h2>
      </div>
      <div className={cx("right")}>
        <Calendar locale="vi" onChange={onChange} value={value} />
      </div>
    </div>
  );
};
export default SecondStep;
