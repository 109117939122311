import classNames from "classnames/bind";
import styles from "./Pads.module.scss";
import icons from "~/assets/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const cx = classNames.bind(styles);

function Pads() {
  return (
    <div className={cx("wrapper")}>
      <div className={cx("title-container")}>
        <h2 className={cx("title")}>
          Chinh phục chuyển nhà với ứng dụng Uhomes
        </h2>
      </div>
      <div className={cx("center")}>
        <div className={cx("container")}>
          {services.map((item) => (
            <Link to={item.link} key={item.className}>
              <div className={cx(item.className)}>
                <div className={cx("itemContainer")}>
                  <div className={cx("imageContainer")}>
                    <img
                      src={item.icon}
                      className={cx("image")}
                      alt={item.name}
                    />
                  </div>
                  <div className={cx("item-text-container")}>
                    <h2 className={cx("item-title")}>{item.name}</h2>
                    <p className={cx("item-subtitle")}>{item.description}</p>
                  </div>

                  <div>
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      inverse
                      size="2x"
                    />
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Pads;

export const services = [
  {
    id: 1,
    name: "Lắp đặt internet & TV",
    icon: icons.internet,
    className: "item1",
    description: "So sánh và chọn gói tốt nhất",
    text: "Kết nối từ ngày đầu tiên. So sánh các nhà cung cấp khu vực của bạn.",
    link: "/internet",
  },
  {
    id: 2,
    name: "Đóng gói & chuyển nhà",
    icon: icons.moving,
    className: "item2",
    description: "Tìm đơn vị chuyển nhà uy tín nhất",
    text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",
    link: "/moving",
  },
  {
    id: 3,
    name: "Dọn nhà theo giờ",
    icon: icons.cleaning,
    className: "item3",
    description: "Giúp ngôi nhà của bạn luôn sạch sẽ",
    text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",
    link: "/cleaning",
  },
  {
    id: 4,
    name: "Dịch vụ điều hòa",
    icon: icons.aircon,
    className: "item4",
    description: "Để điều hòa luôn phục vụ bạn tốt nhất",
    text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",
    link: "/aircon",
  },
  {
    id: 5,
    name: "Sơn nhà",
    icon: icons.painting,
    className: "item5",
    description: "Chuyên viên sơn nhà sẽ thay áo mới cho nhà bạn",
    text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",
    link: "/painting",
  },
  // {
  //   id: 6,
  //   name: "Lắp đặt camera an ninh",
  //   icon: icons.camera,
  //   className: "item6",
  //   description: "Đảm bảo an toàn cho nhà bạn",
  //   text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",

  //   link: "/camera",
  // },
  {
    id: 6,
    name: "Dịch vụ khử trùng",
    icon: icons.sanitization,
    className: "item6",
    description: "Giữ một môi trường sạch sẽ quanh bạn",
    text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",
    link: "/sanitization",
  },
  // {
  //   id: 8,
  //   name: "Vệ sinh sofa nệm thảm rèm",
  //   icon: icons.sofa,
  //   className: "item8",
  //   description: "Mọi thứ luôn sạch mới",
  //   text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",

  //   link: "/internet",
  // },
];
