import React from "react";
import classNames from "classnames/bind";
import styles from "./CardTypeFive.module.scss";

const cx = classNames.bind(styles);

function CardTypeFive({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className="visual-abstraction-inner">
          <img
            alt=""
            className="visual-abstraction-image"
            src="https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto,w_800/updater-dot-com/utilities/pexels-ivan-samkov-7620906.jpg"
          />
          <div className={cx("extra-image")}>
            <img alt="" src={item.imageExtra} />
          </div>
          <div
            className="visual-abstraction-abstraction-width"
            style={{ width: "86%" }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default CardTypeFive;
