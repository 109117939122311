import React, { useEffect, useState } from "react";
import { collection, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import classNames from "classnames/bind";
import styles from "./Admin.module.scss";

const cx = classNames.bind(styles);

const Admin = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [services, setServices] = useState(["Tất cả"]);
  const statuses = ["Tất cả", "Đang xử lý", "Hủy đơn", "Đã hoàn thành"];
  const [selectedService, setSelectedService] = useState("Tất cả");
  const [selectedStatus, setSelectedStatus] = useState("Tất cả");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isProviderPopupVisible, setIsProviderPopupVisible] = useState(false); // Popup chọn nhà cung cấp
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null); // Nhà cung cấp đã chọn
  const [providers, setProviders] = useState([]); // Danh sách nhà cung cấp
  const [selectedCancelReason, setSelectedCancelReason] = useState(""); // Lý do hủy đơn

  const reasons = [
    "Khách hàng không còn nhu cầu nữa",
    "Không tìm được nhà cung cấp",
    "Khách hàng không bắt máy",
    "Khách hàng yêu cầu quá đáng",
  ];

  useEffect(() => {
    const ordersCollection = collection(db, "servicesRequested");
    const providersCollection = collection(db, "providers"); // Lấy danh sách nhà cung cấp

    const unsubscribeOrders = onSnapshot(ordersCollection, (snapshot) => {
      let orderList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      orderList = orderList.sort((a, b) => b.requestAt - a.requestAt);

      setOrders(orderList);
      setFilteredOrders(orderList);

      const uniqueServices = [
        "Tất cả",
        ...new Set(orderList.map((order) => order?.type || order?.nameService)),
      ];
      setServices(uniqueServices);
    });

    // Lấy danh sách nhà cung cấp từ Firestore
    const unsubscribeProviders = onSnapshot(providersCollection, (snapshot) => {
      const providerList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProviders(providerList);
    });

    return () => {
      unsubscribeOrders();
      unsubscribeProviders();
    };
  }, []);

  const updateOrderStatus = async (orderId, newStatus) => {
    const orderRef = doc(db, "servicesRequested", orderId);
    await updateDoc(orderRef, {
      status: newStatus,
      provider: selectedProvider, // Cập nhật nhà cung cấp đã chọn nếu có
      cancelReason: selectedCancelReason, // Cập nhật lý do hủy nếu có
    });
  };

  const handleFilterChange = () => {
    let filtered = orders;

    if (selectedService !== "Tất cả") {
      filtered = filtered.filter(
        (order) => (order?.type || order?.nameService) === selectedService
      );
    }

    if (selectedStatus !== "Tất cả") {
      filtered = filtered.filter((order) => order.status === selectedStatus);
    }

    setFilteredOrders(filtered);
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedService, selectedStatus, orders]);

  // Xử lý nút hủy đơn
  const handleCancelClick = (orderId) => {
    setSelectedOrderId(orderId);
    setSelectedCancelReason(""); // Reset lý do hủy khi mở popup mới
    setIsPopupVisible(true); // Hiển thị popup hủy đơn
  };

  const handlePopupCancel = () => {
    setIsPopupVisible(false);
    setSelectedOrderId(null);
  };

  const handlePopupConfirm = () => {
    if (selectedOrderId && selectedCancelReason) {
      updateOrderStatus(selectedOrderId, "Hủy đơn");
      setIsPopupVisible(false);
    } else {
      alert("Vui lòng chọn lý do hủy đơn");
    }
  };

  const handleCancelReasonChange = (value) => {
    setSelectedCancelReason(value);
  };

  // Xử lý nút "Đã hoàn thành" và popup chọn nhà cung cấp
  const handleProviderSelection = (orderId) => {
    setSelectedOrderId(orderId);
    setIsProviderPopupVisible(true); // Hiển thị popup chọn nhà cung cấp
  };

  const handleProviderPopupConfirm = () => {
    if (selectedProvider) {
      updateOrderStatus(selectedOrderId, "Đã hoàn thành");
      setIsProviderPopupVisible(false);
    } else {
      alert("Vui lòng chọn nhà cung cấp");
    }
  };

  const handleProviderPopupCancel = () => {
    setIsProviderPopupVisible(false);
    setSelectedProvider(null);
  };

  return (
    <div className={cx("container")}>
      <h1 className={cx("heading")}>Quản lý đơn hàng</h1>

      <div className={cx("filters")}>
        <label>
          Lọc theo dịch vụ:
          <select
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            className={cx("filterSelect")}
          >
            {services.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </select>
        </label>

        <label>
          Lọc theo tình trạng đơn:
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className={cx("filterSelect")}
          >
            {statuses.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div className={cx("tableWrapper")}>
        <table className={cx("table")}>
          <thead>
            <tr>
              <th>#</th>
              <th>Service</th>
              <th>Total (VND)</th>
              <th>Phone Number</th>
              <th>Start Point</th>
              <th>End Point</th>
              <th>Location</th>

              <th>Time</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order, index) => (
              <tr key={order.id}>
                <td>{index + 1}</td>
                <td>{order?.nameService}</td>
                <td>
                  {order.total
                    ? Number(order.total).toLocaleString("vi-VN")
                    : "#N/A"}
                </td>
                <td>{order.phoneNumber || order.contact}</td>
                <td>{order.start || "#N/A"}</td>
                <td>{order.finish || "#N/A"}</td>
                <td>{order.location || "#N/A"}</td>

                <td>{`${order.time} - ${order.date}`}</td>
                <td>
                  <span
                    className={cx(
                      "status",
                      order.status === "Hủy đơn" && "cancel",
                      order.status === "Đã hoàn thành" && "completed"
                    )}
                  >
                    {order.status || "Đang xử lý"}
                  </span>
                </td>
                <td>
                  <div className={cx("buttonGroup")}>
                    <button
                      onClick={() => handleCancelClick(order.id)}
                      className={cx("button", "cancelButton")}
                    >
                      Hủy
                    </button>
                    <button
                      onClick={() => handleProviderSelection(order.id)}
                      className={cx("button", "completeButton")}
                    >
                      Đã hoàn thành
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Popup chọn lý do hủy đơn */}
      {isPopupVisible && (
        <div className={cx("popupOverlay")}>
          <div className={cx("popupContent")}>
            <h2>Bạn có chắc chắn muốn hủy đơn này?</h2>
            <div className={cx("cancelReasons")}>
              {reasons.map((reason, index) => (
                <div
                  className={cx("radioContainer")}
                  onClick={() => handleCancelReasonChange(reason)}
                  key={index}
                >
                  <div className={cx("radio")}>
                    {selectedCancelReason === reason && (
                      <div className={cx("dot")} />
                    )}
                  </div>
                  <label className={cx("radioLabel")}>{reason}</label>
                </div>
              ))}
            </div>
            <div className={cx("popupButtonGroup")}>
              <button
                onClick={handlePopupCancel}
                className={cx("popupButton", "skipButton")}
              >
                Bỏ qua
              </button>
              <button
                onClick={handlePopupConfirm}
                className={cx("popupButton", "confirmButton")}
              >
                Xác nhận hủy
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Popup chọn nhà cung cấp */}
      {isProviderPopupVisible && (
        <div className={cx("popupOverlay")}>
          <div className={cx("popupContent")}>
            <h2>Chọn nhà cung cấp</h2>

            <div className={cx("providerList")}>
              {providers.map((provider) => (
                <div
                  className={cx("radioContainer")}
                  key={provider.id}
                  onClick={() => setSelectedProvider(provider)}
                >
                  <div className={cx("radio")}>
                    {selectedProvider?.id === provider.id && (
                      <div className={cx("dot")} />
                    )}
                  </div>

                  <label className={cx("radioLabel")}>{provider.name}</label>
                </div>
              ))}
            </div>
            <div className={cx("popupButtonGroup")}>
              <button
                onClick={handleProviderPopupCancel}
                className={cx("popupButton", "skipButton")}
              >
                Bỏ qua
              </button>
              <button
                onClick={handleProviderPopupConfirm}
                className={cx("popupButton", "confirmButton")}
              >
                Xác nhận
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
