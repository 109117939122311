import classNames from "classnames/bind";
import styles from "./Radios.module.scss";
const cx = classNames.bind(styles);

const Radio = ({ handleClickItem, item, value, divide }) => {
  return (
    <div
      className={cx("radio-item")}
      onClick={() => handleClickItem(item)}
      style={{
        width: divide ? "50%" : "100%",
        // backgroundColor: divide ? "green" : "red",
      }}
    >
      <svg
        className={cx("radio-dot")}
        width="22"
        height="22"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6Z"
          fill={item.value === value ? "#ff7800" : "#F3F4F8"}
        ></path>
      </svg>
      <div className={cx("radio-title")}>{item?.title || item?.name}</div>
    </div>
  );
};

const Radios = ({ list, handleClickItem, value, divide }) => {
  return (
    <div className={cx("container")}>
      {list.map((item) => (
        <Radio
          value={value}
          item={item}
          handleClickItem={handleClickItem}
          divide={divide}
        />
      ))}
    </div>
  );
};

export default Radios;
