import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import { useEffect, useRef } from "react";

const cx = classNames.bind(styles);

const SixthStep = ({ info, setInfo }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className={cx("section")} ref={ref}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Ghi chú cho nhân viên </h2>
      </div>
      <div className={cx("right")}>
        <div className={cx("input-wrapper")}>
          <input
            className={cx("input")}
            name="myInput"
            type="text"
            placeholder="Ghi chú"
            value={info.note}
            onChange={(e) =>
              setInfo((prev) => ({
                ...prev,
                note: e.target.value,
              }))
            }
          />
        </div>
      </div>
    </div>
  );
};
export default SixthStep;
