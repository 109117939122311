import classNames from "classnames/bind";
import CardTypeThree from "~/components/Cards/CardTypeThree";
import SideText from "../SideText";
import styles from "./ThreeOp.module.scss";

const cx = classNames.bind(styles);

function ThreeOp({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("mainSection")}>
        <div className={cx("left-mainSection")}>
          <SideText item={item} />
        </div>
        <div className={cx("right-mainSection")}>
          <CardTypeThree item={item} />
        </div>
      </div>
    </div>
  );
}

export default ThreeOp;
