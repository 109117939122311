import classNames from "classnames/bind";

import { Link } from "react-router-dom";
import Modal from "~/components/Modal";
import { useState } from "react";
import styles from "./Header.module.scss";
import DropMenu from "~/components/Menus/DropMenu";
import { services } from "~/components/Pads";
import icons from "~/assets/icons";
import {
  faBars,
  faChevronDown,
  faChevronUp,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MobileMenu from "~/components/Menus/MobileMenu";
import ModalMobile from "~/components/Menus/MobileMenu/ModalMobile";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const cx = classNames.bind(styles);

function Header() {
  const [modalService, setModalService] = useState(false);
  const [modalPartner, setModalPartner] = useState(false);
  const [modalMobile, setModalMobile] = useState(false);
  const [modalSignIn, setModalSignIn] = useState(false);

  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.currentUser);
  console.log(user);
  return (
    <header className={cx("wrapper")}>
      <div className={cx("inner")}>
        <Link to="/">
          <h2 className={cx("logo-link")}>Uhomes</h2>
        </Link>
        <div className={cx("actions")}>
          <button
            className={cx("action-btn")}
            onClick={() => {
              setModalPartner(false);
              setModalService(!modalService);
            }}
          >
            <span className={cx("action-note")}>Dịch vụ</span>
            {!modalService ? (
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faChevronUp} size="1x" />
            )}
          </button>
          <button
            style={{ flex: 1 }}
            className={cx("action-btn")}
            onClick={() => {
              setModalService(false);
              setModalPartner(!modalPartner);
            }}
          >
            <span className={cx("action-note")}>Đối tác</span>
            {!modalPartner ? (
              <FontAwesomeIcon icon={faChevronDown} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faChevronUp} size="1x" />
            )}
          </button>

          <Modal modalShown={modalSignIn} toggleModal={setModalSignIn} />
          <div className={cx("login-button")}>
            {user ? (
              <div
                className={cx("profile")}
                onClick={() => navigate("/dashboard")}
              >
                <img
                  src={
                    user?.photoURL ||
                    "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
                  }
                  alt="Profile"
                  className={cx("profile-image")}
                />
                <span className={cx("profile-name")}>
                  {user?.displayName || user?.phoneNumber || user?.email}
                </span>
              </div>
            ) : (
              <Button primary onClick={() => navigate("/login")}>
                Đăng nhập
              </Button>
            )}
          </div>
          <button
            className={cx("action-icon")}
            onClick={() => {
              setModalService(false);
              setModalPartner(false);

              setModalMobile(!modalMobile);
            }}
          >
            {!modalMobile ? (
              <FontAwesomeIcon icon={faBars} size="2x" />
            ) : (
              <FontAwesomeIcon icon={faXmark} size="2x" />
            )}
          </button>
          <Modal
            height="500px"
            modalShown={modalService}
            toggleModal={setModalService}
          >
            <DropMenu
              item={itemService}
              internet
              onCloseModal={setModalService}
            />
          </Modal>
          <Modal
            height="360px"
            modalShown={modalPartner}
            toggleModal={setModalPartner}
          >
            <DropMenu item={itemPartner} onCloseModal={setModalPartner} />
          </Modal>
          <ModalMobile modalShown={modalMobile} toggleModal={setModalMobile}>
            <MobileMenu
              itemService={itemService}
              itemPartner={itemPartner}
              onCloseModal={setModalMobile}
            />
          </ModalMobile>
        </div>
      </div>
    </header>
  );
}

export default Header;

const itemService = {
  preLeft: "Dành cho khách hàng",
  title: "Chúng tôi cung cấp giải pháp chuyển nhà từ đầu đến cuối",
  text: "Uhomes là ứng dụng chuyển nhà được hỗ trợ bởi công nghệ tiên tiến.",
  button: "Đọc thêm kinh nghiệm",
  linkBtn: "",
  preRight: "Danh sách dịch vụ",
  list: services,
};

const itemPartner = {
  preLeft: "Dành cho đối tác",
  title: "Gia tăng trải nghiệm phục vụ khách hàng của bạn",
  text: "Khai thác nền tảng công nghệ chuyển nhà đầy đủ nhất Việt Nam.",
  button: "Đọc tin tức mới nhất",
  linkBtn: "",
  preRight: "Đối tượng hợp tác",
  list: [
    {
      id: 1,
      name: "Các nhà cung cấp",
      icon: icons.internet,
      className: "item1",
      description: "So sánh và chọn gói tốt nhất",
      text: "Kết nối từ ngày đầu tiên. So sánh các nhà cung cấp khu vực của bạn.",
      link: "/solutions",
    },
    {
      id: 2,
      name: "Đại lý bất động sản",
      icon: icons.moving,
      className: "item2",
      description: "Tìm đơn vị chuyển nhà uy tín nhất",
      text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",

      link: "/agency",
    },
    {
      id: 3,
      name: "Quản lý bất động sản",
      icon: icons.cleaning,
      className: "item3",
      description: "Giúp ngôi nhà của bạn luôn sạch sẽ",
      text: "Kết nối từ ngày đầu tiên. SO sánh các nhà cung cấp khu vực của bạn.",

      link: "/providers",
    },
  ],
};
