import classNames from "classnames/bind";
import styles from "./Home.module.scss";
import Pads from "~/components/Pads";
import TextAndImage from "~/components/TextAndImage/GeneralTIandIT";
import Columns from "~/components/Columns";
import Providers from "~/components/Providers";
import Sliders from "~/components/Sliders";
import It from "~/components/TextAndImage/It";
import Ti from "~/components/TextAndImage/Ti";
import {
  HomeColumnTwo,
  HomeColumnOne,
  HomeColumnThree,
} from "~/components/Icons";
import images from "~/assets/images";
import { useSelector } from "react-redux";

const cx = classNames.bind(styles);

function Home() {
  const currentUser = useSelector((state) => state.auth);

  console.log(currentUser);
  return (
    <div className={cx("main")}>
      <TextAndImage item={itemTextAndImage} />
      <Pads />
      <Ti item={itemPartner} />
      <It item={itemCustomer} />
      <Columns item={itemColumns} />
      <Providers item={itemProviders} />
      <Sliders item={itemSliders} movingTips />
    </div>
  );
}

export default Home;

const itemTextAndImage = {
  title: "Chuyển nhà chưa bao giờ dễ như bây giờ",
  text: "Hãy sử dụng công nghệ để mọi thứ trở nên thuận tiện và nhanh chóng hơn.",
  media: {
    imageUrl:
      "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/homepage/homepage-hero-people.png",
  },
};

const itemProviders = {
  title: "Hợp tác với các nhà cung cấp yêu thích của Việt Nam",
  text: "Mọi thứ bạn cần cho ngôi nhà mới của mình được cung cấp bởi hàng trăm thương hiệu đáng tin cậy nhất trên toàn quốc.",
  providers: [
    "https://fpt-fti.com/wp-content/uploads/2020/12/FPT_logo.png",
    "https://via.org.vn/datafiles/setone/1620884668_Logo_Viettel-Telecom.png",
    "https://itvnpt.vn/wp-content/uploads/2021/11/Logo-VNPT-TP-HCM-1.png",
    "https://cdn.haitrieu.com/wp-content/uploads/2022/05/Logo-Lalamove-V.png",
    "https://home.ahamove.com/wp-content/uploads/2021/01/Logo-dung-full-color-moi-2022-03.png",
    "https://www.btaskee.com/wp-content/uploads/2018/08/bTaskee-Logo.png",
    "https://jupviec.vn/_nuxt/img/logo.50db9bf.png",
  ],
};

const itemColumns = {
  title: "Tìm thấy cách tốt hơn để chuyển nhà",
  text: "Chúng tôi đảm nhận mọi chi tiết thay bạn",
  intros: [
    {
      id: 1,
      title: "Tất cả các nhiệm vụ chuyển nhà của bạn ở một nơi",
      subtile1:
        "Từ đặt xe tải chở đồ đến dọn dẹp nhà cửa: luôn cập nhật mọi thứ bạn cần làm trong một ứng dụng.",
      subtile2: "Nhận các mẹo chuyển nhà hữu ích và lời nhắc kịp thời.",
      icon: <HomeColumnOne />,
    },
    {
      id: 2,
      title: "Cá nhân hóa, người hỗ trợ riêng",
      subtile1:
        "Vâng, bạn có thể nói chuyện với một con người! Trình cập nhật kết hợp dịch vụ trực tiếp tốt nhất với công nghệ trực quan.",
      subtile2:
        "Đội ngũ giàu kinh nghiệm của chúng tôi luôn sẵn sàng hỗ trợ bạn khi bạn cần.",
      icon: <HomeColumnTwo />,
    },
    {
      id: 3,
      title: "Tìm kiếm, so sánh và tự tin mua sắm",
      subtile1:
        "So sánh các lựa chọn từ các nhà cung cấp internet, TV, vật liệu đóng gói và thậm chí cả các bên chuyển nhà hàng đầu của quốc gia.",
      subtile2:
        "Thực hiện lựa chọn phù hợp với bạn mà không cần phải rời khỏi ứng dụng.",
      icon: <HomeColumnThree />,
    },
  ],
};

const itemCustomer = {
  pre: "DÀNH CHO ĐỐI TÁC",
  title: "Cung cấp cho khách hàng của bạn một trải nghiệm chuyển nhà liền mạch",
  text: "Tự động hóa quy trình chuyển đến cho cư dân đồng thời gia tăng độ hài lòng đối với chất lượng dịch vụ môi giới của bạn. Uhomes mang đến các giải pháp thuận tiện và tiết kiệm thời gian được tùy chỉnh cho người mua/thuê cũng như người bán/cho thuê.",

  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/dudewithkids.jpg",
  imageExtra: images.frontpage,
};

const itemPartner = {
  pre: "CHO BẠN CHUYỂN NHÀ",
  title: "Ổn định chỗ ở sớm hơn với một ứng dụng duy nhất",
  text: "Chuyển nhà là lộn xộn, nhưng nó không phải thế nữa. Dễ dàng cung cấp năng lượng cho các tác vụ di chuyển của bạn như đóng gói đồ đạc, bốc xếp và dọn dẹp trước khi vào. Tích hợp sâu của chúng tôi cũng cho phép bạn mua sắm và so sánh các giao dịch tuyệt vời cho tìm xe tải, đặt internet, v.v. Tất cả trong một ứng dụng.",
};

export const itemSliders = {
  title: "Mẹo, thủ thuật & kinh nghiệm chuyển nhà",
  text: "",
  list: [
    {
      id: 1,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617737158/shutterstock_720994654/shutterstock_720994654.jpg?_i=AA",
      title:
        "Nghi thức chuyển nhà: 9 mẹo giúp ngày chuyển nhà của bạn suôn sẻ hơn",
      description:
        "Khi ngày chuyển nhà cuối cùng cũng đến, bạn có thể thở phào nhẹ nhõm. Những ngày đóng gói và lên kế hoạch đã qua, và bây giờ tất cả những gì còn lại là ổn định cuộc sống tại ngôi nhà mới của bạn. Tuy nhiên, ngày chuyển nhà là một nỗ lực hợp tác — cho dù bạn đang làm việc với những người chuyển nhà chuyên nghiệp hay nhờ sự giúp đỡ của bạn bè, bạn nên cân nhắc xem các nghi thức chuyển nhà giúp quá trình này trở nên dễ dàng hơn như thế nào đối với mọi người tham gia vào ngày trọng đại.",
      introDetails:
        "Dưới đây là chín bước bạn có thể thực hiện trong quá trình chuyển nhà để khiến một ngày trôi qua nhanh chóng và giữ cho mọi người có tinh thần tốt.",
      details: [
        {
          id: 1,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Khi bạn thức dậy vào ngày chuyển nhà, đồ đạc của bạn đã được đóng gói, dán nhãn và sẵn sàng để chất lên xe tải. Bạn có thể cần đóng gói một số đồ vệ sinh cá nhân và khăn trải giường vào buổi sáng ngày chuyển đi, nhưng phần lớn việc đóng gói nên được hoàn thành trước. Điều này cho phép máy động lực của bạn đến và làm việc ngay lập tức.",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 2,
          title: "Đóng gói gọn gàng, dán nhãn rõ ràng",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 3,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999723/shutterstock_1170001924/shutterstock_1170001924.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 4,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 5,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 6,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 7,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999373/shutterstock_1557782294/shutterstock_1557782294.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 8,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 9,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
      ],
    },
    {
      id: 2,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/w_1024,h_683/f_auto,q_auto/v1603519436/calculator-desk_how-to-save-on-cable-internet-and-phone/calculator-desk_how-to-save-on-cable-internet-and-phone.jpg?_i=AA",
      title: "How to Save on Internet, Cable, and Phone",
      description:
        "Moving is expensive and knowing how to save on your internet, cable, and phone bills can really help balance out that spending. Curious about the best ways to save? Turns out, it’s not such a challenge once you know how. Here are 11 of our tried-and-true techniques for how you can save on internet, cable, and phone while still getting the most out of your monthly payments.",
    },
    {
      id: 3,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1619369585/family-sitting-on-sofa-i-1/family-sitting-on-sofa-i-1.jpg?_i=AA",
      title: "Moving While Expecting Kids? Be Sure to Set up TV and Internet",
      description:
        "Of all the things you deal with as a new parent during a household move, your belongings may be the most obvious. But what about services? When it comes to TV and internet, it pays to plan ahead and make sure you have service for every step of your journey. Here’s how to ensure you’re prepared to tackle parenthood, especially in your new home.",
    },
  ],
};
