import classNames from "classnames/bind";
import CardTypeFive from "~/components/Cards/CardTypeFive";
import SideText from "../SideText";
import styles from "./Five.module.scss";

const cx = classNames.bind(styles);

function Five({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("mainSection")}>
        <div className={cx("right-mainSection")}>
          <CardTypeFive item={item} />
        </div>
        <div className={cx("left-mainSection")}>
          <SideText item={item} />
        </div>
      </div>
    </div>
  );
}

export default Five;
