import AirconItem from "./AirconItem";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "~/config/firebase";
import { useState, useEffect } from "react";

const AirconHangWall = ({ activeTab, idx, ...props }) => {
  const [list, setList] = useState([]);
  const getInfo = async () => {
    try {
      const q = query(
        collection(db, "airconPriceTable"),
        where("type", "==", "AirconHangWall")
      );
      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setList(data.sort((a, b) => a.sort - b.sort));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div
      className="animate__animated animate__fadeInLeft"
      role="tabpanel"
      {...props}
    >
      {activeTab === idx && (
        <div className="container-page">
          <img
            src="https://dienmayhlp.com/cdn/images/Diều%20hoa/quy-trinh-lap-dat-dieu-hoa-treo-tuong-chuan-02.jpg"
            alt="react logo"
            style={{
              width: "100%",
              height: 240,
              objectFit: "cover",
              marginBottom: 24,
            }}
          />
          <div style={{ fontSize: 18, marginBottom: 24 }}>
            Danh sách điều hòa treo tường
          </div>

          {list.map((item) => (
            <AirconItem
              key={item.id}
              item={item}
              title={item.title}
              subtitle={item.subtitle}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default AirconHangWall;
