import React, { useEffect, useState } from "react";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import classNames from "classnames/bind";
import styles from "./Cms.module.scss";

const cx = classNames.bind(styles);

const Cms = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Tất cả");
  const statuses = ["Tất cả", "Admin", "Môi giới", "Người dùng"];

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users");
      const userSnapshot = await getDocs(usersCollection);
      let userList = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      userList = userList.sort((a, b) => b.lastLogin - a.lastLogin);

      setUsers(userList);
      setFilteredUsers(userList);
    };

    fetchUsers();
  }, []);

  const handleFilterChange = () => {
    let filtered = users;

    if (selectedStatus !== "Tất cả") {
      filtered = filtered.filter(
        (user) =>
          (selectedStatus === "Admin" && user.isAdmin) ||
          (selectedStatus === "Môi giới" && user.isBroker) ||
          (selectedStatus === "Người dùng" && !user.isAdmin && !user.isBroker)
      );
    }

    setFilteredUsers(filtered);
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedStatus]);

  return (
    <div className={cx("container")}>
      <h1 className={cx("heading")}>Quản lý khách hàng</h1>

      <div className={cx("filters")}>
        <label>
          Lọc theo quyền hạn:
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            className={cx("filterSelect")}
          >
            {statuses.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div className={cx("tableWrapper")}>
        <table className={cx("table")}>
          <thead>
            <tr>
              <th>#</th>
              <th>ID khách hàng</th>
              <th>Thiết bị đăng ký</th>
              <th>Thời gian đăng ký</th>
              <th>Phương thức đăng ký</th>

              <th>Vai trò</th>
              <th>Họ tên</th>

              <th>Số điện thoại</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((order, index) => (
              <tr key={order.id}>
                <td>{index + 1}</td>
                <td>{order.uid}</td>
                <td>
                  {order?.methodRegister === "phone" ? "iPhone" : "Android"}
                </td>

                <td>{order.timeSignup || "#N/A"}</td>
                <td>{order.methodRegister}</td>

                <td>{order.status || "Khách hàng"}</td>
                <td>{order.displayName || "#N/A"}</td>

                <td>{order.phoneNumber || "#N/A"}</td>
                <td className={cx("emailColumn")}>{order.email || "#N/A"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Cms;
