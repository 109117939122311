import React from "react";
import CardTypeFive from "~/components/Cards/CardTypeFive";
import CardTypeFour from "~/components/Cards/CardTypeFour";
import CardTypeOne from "~/components/Cards/CardTypeOne";
import CardTypeThree from "~/components/Cards/CardTypeThree";
import CardTypeTwo from "~/components/Cards/CardTypeTwo";
import MapSearch from "./MapSearch";

function Blog() {
  return (
    <div style={{ marginTop: 110, paddingBottom: 200 }}>
      <MapSearch />
    </div>
  );
}

export default Blog;
