import classNames from "classnames/bind";
import styles from "./Modal.module.scss";

const cx = classNames.bind(styles);

const Modal = ({ children, modalShown, handleClose }) => {
  return modalShown ? (
    <div className={cx("modal-backdrop")} onClick={handleClose}>
      <div
        className={cx("modal-content")}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  ) : null;
};

export default Modal;
