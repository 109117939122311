import React from "react";

function CardTypeThree({ item }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 345 430"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className="blue"
          x="0"
          y="30.536"
          width="219.41"
          height="334.6"
          rx="24"
          transform="rotate(-8 0 30.536)"
          fill="#54d79b"
        ></rect>
        <foreignObject
          className="svg-image-video"
          x="91.1523"
          y="80"
          width="253"
          height="350"
        >
          <div
            className="svg-image-video-image"
            style={{
              borderRadius: "24px",
              width: "100%",
              height: "100%",
              backgroundPosition: "50%",
              backgroundSize: "cover",
              backgroundImage: `url(${item.imageUrl})`,
            }}
          ></div>
        </foreignObject>
      </svg>
    </div>
  );
}

export default CardTypeThree;
