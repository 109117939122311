import classNames from "classnames/bind";
import CardTypeOne from "~/components/Cards/CardTypeOne";
import SideText from "../SideText";
import styles from "./Ti.module.scss";

const cx = classNames.bind(styles);

function Ti({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("mainSection")}>
        <div className={cx("left-mainSection")}>
          <SideText item={item} />
        </div>
        <div className={cx("right-mainSection")}>
          <CardTypeOne item={item} />
        </div>
      </div>
    </div>
  );
}

export default Ti;
