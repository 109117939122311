import React, { useEffect, useState } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import ThirdStep from "./ThirdStep";
import SeventhStep from "./SeventhStep";

import SmallLayout from "~/components/SmallLayout";
import { useDispatch, useSelector } from "react-redux";
import { airconTotal } from "~/redux/actions/aircon";

const cx = classNames.bind(styles);

function AirconSteps() {
  const [info, setInfo] = useState({
    type: "",
    date: "",
    time: "",
    start: "",
    finish: "",
    note: "",
  });
  const aircon = useSelector((state) => state.aircon);
  const dispatch = useDispatch();

  useEffect(() => {
    const res = aircon.items.reduce((total, currentValue) => {
      // console.log(total);
      return (
        total +
        (currentValue.price + currentValue.gasPrice) * currentValue.quantity
      );
    }, 0);
    dispatch(airconTotal(res));
  }, [aircon.items, dispatch]);

  console.log(aircon);
  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <h2>Yêu cầu báo giá bảo dưỡng điều hòa</h2>
          <FirstStep info={info} setInfo={setInfo} />
          <SecondStep info={info} setInfo={setInfo} />

          <ThirdStep info={info} setInfo={setInfo} />

          <FourthStep info={info} setInfo={setInfo} />

          <FifthStep info={info} setInfo={setInfo} />

          <SeventhStep info={info} setInfo={setInfo} />
        </div>
      </div>
    </SmallLayout>
  );
}

export default AirconSteps;
