import React from "react";
import classNames from "classnames/bind";
import styles from "./SuccessPage.module.scss";
const cx = classNames.bind(styles);

function SuccessPage() {
  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ marginTop: "32px" }}
        >
          <circle cx="24" cy="24" r="24" fill="#ff7800"></circle>
          <path
            d="M15.9999 24.4029L21.7551 30.4782L33.1428 18.2856"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="square"
          ></path>
        </svg>

        <h2>Yêu cầu tư vấn đã được hoàn thành</h2>

        <span>
          Chúng tôi sẽ liên hệ với bạn trong vòng 30 phút qua số 0968205413 để
          tư vấn.
        </span>
      </div>
    </div>
  );
}

export default SuccessPage;
