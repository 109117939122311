import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import { useEffect, useRef } from "react";
import Checkboxs from "~/components/Checkboxs";

const cx = classNames.bind(styles);

const FourthStep = ({ info, setInfo }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const plus = (value) => {
    setInfo((state) => ({
      ...state,
      purposeOfInternet: [...state.purposeOfInternet, value],
    }));
  };

  const minus = (value) => {
    setInfo((state) => ({
      ...state,
      purposeOfInternet: state.purposeOfInternet.filter(
        (item) => item !== value
      ),
    }));
  };

  return (
    <div className={cx("section")} ref={ref}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Bạn dùng Internet để? </h2>
      </div>
      <div className={cx("right")}>
        <Checkboxs
          data={info.purposeOfInternet}
          list={internetPurposes}
          plus={plus}
          minus={minus}
        />
      </div>
    </div>
  );
};
export default FourthStep;

export const internetPurposes = [
  {
    name: "Duyệt web và email",
    price: 1.2,
  },
  {
    name: "Chương trình TV và phim ảnh",
    price: 2.0,
  },
  {
    name: "Chơi game online",
    price: 2.5,
  },
  {
    name: "Kết nối thiết bị nhà thông minh",
    price: 3.0,
  },
];
