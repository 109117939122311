import React, { useState } from "react";
import styles from "./Signup.module.scss";
import classNames from "classnames/bind";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { userAuthStateListener, register } from "../../../redux/actions/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const cx = classNames.bind(styles);

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await dispatch(register(email, password));
      // await dispatch(userAuthStateListener()); // Cập nhật thông tin người dùng ngay sau khi đăng ký thành công

      navigate("/");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setError("Tài khoản email này đã tồn tại.");
      } else if (error.code === "auth/") {
        setError(error.code);
      } else {
        setError(error.code);
      }
    }
  };

  return (
    <div className={cx("main")}>
      <h2 className={cx("heading")}>Đăng ký</h2>

      <div className={cx("content")}>
        <div className={cx("title-container")}>
          <span>Email</span>
          <Link to="/phoneNumber">
            <span className={cx("link")}>Tạo với số điện thoại</span>
          </Link>
        </div>
        <ul>
          <li>
            <input
              type="email"
              name="usr_email"
              id="usr_email"
              placeholder="Địa chỉ email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </li>
          <li>
            <div className={cx("password-container")}>
              <input
                type={showPassword ? "text" : "password"}
                name="usr_password"
                id="usr_password"
                placeholder="Mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button
                type="button"
                className={cx("show-password-button")}
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </li>
          {error && (
            <li>
              <p className={cx("error-message")}>{error}</p>
            </li>
          )}
          <li>
            <p className={cx("text")}>
              Việc <strong>Tạo tài khoản</strong>, bạn đồng ý{" "}
              <a href="/terms/" className={cx("link")} target="_blank">
                Điều khoản sử dụng
              </a>{" "}
              và{" "}
              <a href="/privacy/" className={cx("link")} target="_blank">
                Chính sách riêng tư
              </a>
              .
            </p>
          </li>

          <li>
            <Button primary onClick={handleSignup}>
              Tạo tài khoản
            </Button>
          </li>
        </ul>
      </div>
      <div className={cx("bottom")}>
        <p className="tc mt3">
          Đã có tài khoản?{" "}
          <Link to="/login" className={cx("link")}>
            Đăng nhập
          </Link>
          .
        </p>
      </div>
    </div>
  );
}

export default Signup;
