import {
  INTERNET_DATE,
  INTERNET_DISCOUNT_TYPE,
  INTERNET_NOTE_PROS,
  INTERNET_PACKAGE,
  INTERNET_PAYMENT_TYPE,
  INTERNET_DEVICES,
  INTERNET_TYPE,
  INTERNET_TIME,
  RESET_INTERNET,
  INTERNET_LOCATION,
  INTERNET_CONTACT,
  INTERNET_TOTAL,
  SET_INTERNET_PURPOSE,
  MINUS_INTERNET_PURPOSE,
  SET_TV_PURPOSE,
  MINUS_TV_PURPOSE,
} from "../constants";

const initialState = {
  provider: "",
  package: "",
  type: "",
  price: "",
  paymentType: "",
  discount: 0,
  total: "",
  date: "",
  time: "",
  numberOfDevices: "",
  notePros: "",
  contact: "",
  purposeInternet: [],
  purposeTV: [],
};

export const internet = (state = initialState, action) => {
  switch (action.type) {
    case INTERNET_DEVICES:
      return {
        ...state,
        numberOfDevices: action.payload,
      };

    case INTERNET_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case INTERNET_PACKAGE:
      return {
        ...state,
        package: action.payload.name,
        provider: action.payload.provider,
        price: action.payload.priceMonthly,
      };
    case INTERNET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload,
        total: action.payload * state.price,
      };
    case INTERNET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case SET_INTERNET_PURPOSE:
      return {
        ...state,
        purposeInternet: [...state.purposeInternet, action.payload],
      };
    case MINUS_INTERNET_PURPOSE:
      return {
        ...state,
        purposeInternet: state.purposeInternet.filter(
          (item) => item !== action.payload
        ),
      };
    case SET_TV_PURPOSE:
      return {
        ...state,
        purposeTV: [...state.purposeTV, action.payload],
      };
    case MINUS_TV_PURPOSE:
      return {
        ...state,
        purposeTV: state.purposeTV.filter((item) => item !== action.payload),
      };
    case INTERNET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case INTERNET_DISCOUNT_TYPE:
      return {
        ...state,
        discount: action.payload,
      };

    case INTERNET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case INTERNET_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case INTERNET_NOTE_PROS:
      return {
        ...state,
        notePros: action.payload,
      };
    case INTERNET_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case RESET_INTERNET:
      return initialState;
    default:
      return state;
  }
};
