import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import Button from "~/components/Button/Button";

const cx = classNames.bind(styles);

const SeventhStep = ({ info }) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className={cx("bottom")} ref={ref}>
      <div className={cx("inner")}>
        <Button
          disabled={!info.address ? true : false}
          primary
          onClick={() => navigate("/internet/internetPackages")}
        >
          Tìm kiếm các gói phù hợp
        </Button>
      </div>
    </div>
  );
};
export default SeventhStep;
