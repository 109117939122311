import classNames from "classnames/bind";
import styles from "./CardsOther.module.scss";

import AllText from "~/components/AllText";
import CardTypeFour from "~/components/Cards/CardTypeFour";

const cx = classNames.bind(styles);

function CardsOther({ item, bottom }) {
  return (
    <div className={cx("container")}>
      <div className={cx("mainSection")}>
        <div className={cx("left-mainSection")}>
          <AllText item={item} bottom={bottom} />
        </div>
        <div className={cx("right-mainSection")}>
          <CardTypeFour item={item} />
        </div>
      </div>
    </div>
  );
}

export default CardsOther;
