import React from "react";

function CardTypeOne({ item }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 344 430"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="blue"
        x="296.24"
        y="362.102"
        width="219.407"
        height="334.596"
        rx="24"
        transform="rotate(-171.94 296.24 362.102)"
        fill="#54d79b"
      ></rect>
      <foreignObject
        className="svg-image-video"
        x="0"
        y="80"
        width="253"
        height="350"
      >
        <div
          className="svg-image-video-image"
          style={{
            borderRadius: "24px",
            width: "100%",
            height: "100%",
            backgroundPosition: "50%",
            backgroundSize: "cover",
            backgroundImage: `url(${
              item?.imageUrl ||
              "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/homepage/windows-4yBeMayTYys-unsplash-1.jpg"
            })`,
          }}
        ></div>
      </foreignObject>
    </svg>
  );
}

export default CardTypeOne;
