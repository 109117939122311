import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import { useEffect, useRef } from "react";
import Button from "~/components/Button/Button";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

const SeventhStep = ({ info, setInfo }) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className={cx("button-wrapper")} ref={ref}>
      <Button onClick={() => navigate("/phone")} primary>
        Tiếp tục
      </Button>
    </div>
  );
};
export default SeventhStep;
