import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import {
  browserSessionPersistence,
  browserPopupRedirectResolver,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDCPvR-sc8FO-zdRsjwzrz6Af3tRz7iYQA",
  authDomain: "mvpweb-820e2.firebaseapp.com",
  databaseURL:
    "https://mvpweb-820e2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "mvpweb-820e2",
  storageBucket: "mvpweb-820e2.appspot.com",
  messagingSenderId: "131317090780",
  appId: "1:131317090780:web:012819cbf0bf54cc502719",
  measurementId: "G-GTXLG95642",
};

const app = firebase.initializeApp(firebaseConfig, {
  persistence: browserSessionPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

export const db = getFirestore();
export const storage = getStorage();
export const auth = firebase.auth();
auth.languageCode = "vi";

getAnalytics(app);

export const uploadImage = async (file, userId) => {
  if (!file || !userId) return null;
  const storageRef = ref(storage, `photos/${userId}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

export default firebase;
