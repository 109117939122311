import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./Jobs.module.scss";

const cx = classNames.bind(styles);

const Jobs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    workTime: "fulltime",
    note: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add your form submission logic here
  };

  return (
    <div className={cx("main")}>
      <div className={cx("recruitment-form-container")}>
        <h2 className={cx("form-heading")}>Thông tin ứng viên</h2>
        <form onSubmit={handleSubmit} className={cx("form")}>
          <label className={cx("form-label")}>
            Họ và tên:
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              className={cx("form-input")}
            />
          </label>

          <label className={cx("form-label")}>
            Số điện thoại:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              className={cx("form-input")}
            />
          </label>

          <label className={cx("form-label")}>
            Thời gian làm việc:
            <div className={cx("form-select")}>
              <select
                name="workTime"
                value={formData.workTime}
                onChange={handleChange}
                className={cx("form-input")}
              >
                <option value="fulltime">Full-time</option>
                <option value="parttime">Part-time</option>
              </select>
            </div>
          </label>

          <label className={cx("form-label")}>
            Ghi chú:
            <textarea
              name="note"
              value={formData.note}
              onChange={handleChange}
              className={cx("form-input", "form-textarea")}
            ></textarea>
          </label>

          <button type="submit" className={cx("form-button")}>
            Gửi
          </button>
        </form>
      </div>
    </div>
  );
};

export default Jobs;
