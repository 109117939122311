import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import styles from "./MobileMenu.module.scss";
import DropMenu from "./DropMenu";
import { useState } from "react";

const cx = classNames.bind(styles);

function MobileMenu({ itemService, itemPartner, onCloseModal }) {
  const [modalService, setModalService] = useState(false);
  const [modalPartner, setModalPartner] = useState(false);

  const element = document.getElementById("content-id");

  return (
    <div className={cx("container")}>
      <div id="content-id" className={cx("section")}>
        <button
          className={cx("item")}
          onClick={() => {
            setModalPartner(false);
            setModalService(!modalService);
          }}
        >
          Dịch vụ
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {modalService && (
          <DropMenu item={itemService} internet onCloseModal={onCloseModal} />
        )}
      </div>
      <div
        className={cx("section")}
        onClick={() => {
          setModalService(false);
          setModalPartner(!modalPartner);
          element?.scrollIntoView();
        }}
      >
        <button className={cx("item")}>
          Đối tác
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {modalPartner && (
          <DropMenu item={itemPartner} onCloseModal={onCloseModal} />
        )}
      </div>
    </div>
  );
}

export default MobileMenu;
