import React, { useEffect, useState } from "react";
import { collection, onSnapshot, addDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import classNames from "classnames/bind";
import styles from "./Supply.module.scss";

const cx = classNames.bind(styles);

const Supply = () => {
  const [providers, setProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [selectedService, setSelectedService] = useState("Tất cả");
  const [newProviderName, setNewProviderName] = useState("");
  const [newServiceType, setNewServiceType] = useState("Đóng gói & Chuyển nhà");
  const [newLocation, setNewLocation] = useState("Hà Nội");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const services = [
    "Đóng gói & Chuyển nhà",
    "Lắp đặt Internet TV",
    "Dọn dẹp theo giờ",
    "Vệ sinh điều hòa",
    "Dịch vụ sơn nhà",
    "Vệ sinh máy giặt",
  ];
  const locations = ["Hà Nội", "TP. HCM"];

  useEffect(() => {
    const fetchProviders = () => {
      const providersCollection = collection(db, "providers");

      const unsubscribe = onSnapshot(providersCollection, (snapshot) => {
        let providerList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        providerList = providerList.sort(
          (a, b) => b.createdDate - a.createdDate
        );

        setProviders(providerList);
        setFilteredProviders(providerList);
      });

      return () => unsubscribe();
    };

    fetchProviders();
  }, []);

  const handleFilterChange = () => {
    let filtered = providers;

    if (selectedService !== "Tất cả") {
      filtered = filtered.filter(
        (provider) => provider.serviceType === selectedService
      );
    }

    setFilteredProviders(filtered);
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedService, providers]);

  const handleAddProvider = async () => {
    if (
      !newProviderName ||
      !newServiceType ||
      !newLocation ||
      !newPhoneNumber
    ) {
      alert("Vui lòng điền đầy đủ thông tin.");
      return;
    }

    const newProvider = {
      name: newProviderName,
      service: newServiceType,
      location: newLocation,
      phoneNumber: newPhoneNumber,
      email: newEmail || null,
      createdDate: new Date().getTime(),
      orderCount: 0,
      completedOrders: 0,
    };

    try {
      await addDoc(collection(db, "providers"), newProvider);
      setNewProviderName("");
      setNewServiceType("Đóng gói & Chuyển nhà");
      setNewLocation("Hà Nội");
      setNewPhoneNumber("");
      setNewEmail("");
    } catch (error) {
      console.error("Lỗi khi thêm nhà cung cấp:", error);
    }
  };

  return (
    <div className={cx("container")}>
      <h1 className={cx("heading")}>Quản lý nhà cung cấp</h1>

      <div className={cx("filters")}>
        <label>
          Lọc theo dịch vụ:
          <select
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            className={cx("filterSelect")}
          >
            <option value="Tất cả">Tất cả</option>
            {services.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </select>
        </label>
      </div>

      <div className={cx("tableWrapper")}>
        <table className={cx("table")}>
          <thead>
            <tr>
              <th>#</th>
              <th>Tên nhà cung cấp</th>
              <th>Liên hệ</th>
              <th>Email</th>
              <th>Loại dịch vụ</th>
              <th>Khu vực</th>
              <th>Ngày tạo</th>
              <th>Số đơn hàng</th>
              <th>ID nhà cung cấp</th>
            </tr>
          </thead>
          <tbody>
            {filteredProviders.map((provider, index) => (
              <tr key={provider.id}>
                <td>{index + 1}</td>
                <td>{provider.name || "Chưa xác định"}</td>
                <td>{provider.phoneNumber || "#N/A"}</td>
                <td className={cx("emailColumn")}>
                  {provider.email || "#N/A"}
                </td>
                <td>{provider.service || "Chưa xác định"}</td>
                <td>{provider.location || "Chưa xác định"}</td>
                <td>
                  {provider.createdDate
                    ? new Date(provider.createdDate).toLocaleDateString()
                    : "#N/A"}
                </td>
                <td>{provider.orderCount || "0"}</td>
                <td>{provider.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={cx("addProviderForm")}>
        <h2>Thêm nhà cung cấp mới</h2>
        <div className={cx("formGroup")}>
          <label>Tên nhà cung cấp:</label>
          <input
            type="text"
            value={newProviderName}
            onChange={(e) => setNewProviderName(e.target.value)}
            placeholder="Nhập tên nhà cung cấp"
            className={cx("input")}
          />
        </div>
        <div className={cx("formGroup")}>
          <label>Số điện thoại:</label>
          <input
            type="text"
            value={newPhoneNumber}
            onChange={(e) => setNewPhoneNumber(e.target.value)}
            placeholder="Nhập số điện thoại"
            className={cx("input")}
          />
        </div>
        <div className={cx("formGroup")}>
          <label>Email (không bắt buộc):</label>
          <input
            type="text"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Nhập email (nếu có)"
            className={cx("input")}
          />
        </div>
        <div className={cx("formGroup")}>
          <label>Chọn dịch vụ:</label>
          <select
            value={newServiceType}
            onChange={(e) => setNewServiceType(e.target.value)}
            className={cx("select")}
          >
            {services.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </select>
        </div>
        <div className={cx("formGroup")}>
          <label>Chọn khu vực:</label>
          <select
            value={newLocation}
            onChange={(e) => setNewLocation(e.target.value)}
            className={cx("select")}
          >
            {locations.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>
        <button onClick={handleAddProvider} className={cx("addButton")}>
          Thêm nhà cung cấp
        </button>
      </div>
    </div>
  );
};

export default Supply;
