import classNames from "classnames/bind";
import styles from "./Card.module.scss";

const cx = classNames.bind(styles);

function Card({ item, handleClickItem }) {
  return (
    <div onClick={handleClickItem} className={cx("container")}>
      <div className={cx("imageContainer")}>
        <img src={item.icon} className={cx("image")} alt={item.name} />
      </div>
      <div className={cx("textContainer")}>
        <span className={cx("label=title")}>{item.name}</span>
        <span className={cx("label-text")}>{item.text} </span>
      </div>
    </div>
  );
}

export default Card;
