import React, { useState } from "react";
import styles from "./Referrals.module.scss";
import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserFriends,
  faGift,
  faEquals,
} from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";

const cx = classNames.bind(styles);

const Referrals = () => {
  const user = useSelector((state) => state.auth.currentUser);
  const [copied, setCopied] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Email:", email);
    console.log("Message:", message);

    window.open(`mailto:${email}?subject=Subject&body=${message}`);
  };
  return (
    <div className={cx("App")}>
      <div className={cx("card")}>
        <img
          className={cx("image")}
          src="https://img-b.udemycdn.com/course/750x422/5437906_3e94.jpg"
          alt="tiep-thi-lien-ket"
        />
        <div className={cx("card-content")}>
          <span className={cx("card-title")}>Chương Trình Giới Thiệu</span>

          <div className={cx("card-icons")}>
            <FontAwesomeIcon icon={faUserFriends} size="2x" color="#f39c12" />
            <FontAwesomeIcon icon={faEquals} size="2x" color="#f39c12" />
            <FontAwesomeIcon icon={faGift} size="2x" color="#f39c12" />
          </div>

          <p>Kiếm tiền bằng việc giới thiệu khách hàng</p>
        </div>
      </div>
      <div className={cx("referral-list")}>
        <div className={cx("copy-link")}>
          <h2 className={cx("title")}>1. Chia sẻ link của bạn</h2>
          <p className={cx("text")}>Kiếm tiền bằng liên kết của riêng bạn</p>
          <input
            type="text"
            value={`https://uhomes.com.vn/r/${user.phoneNumber || user.email}`}
            readOnly
            className={cx("link-input")}
          />
          <Button primary>
            <CopyToClipboard
              text={`https://uhomes.com.vn/r/${user.phoneNumber || user.email}`}
              onCopy={() => setCopied(true)}
            >
              <span> {copied ? "Đã sao chép link!" : "Sao chép link"}</span>
            </CopyToClipboard>
          </Button>
        </div>
        <div className={cx("copy-link")}>
          <h2 className={cx("title")}>2. Chia sẻ qua số điện thoại</h2>
          <p className={cx("text")}>
            Mời mọi người bằng cách nhập số điện thoại của họ.
          </p>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Nhập số điện thoại khách hàng"
            className={cx("link-input")}
          />
          <Button primary to="/gui-thanh-cong">
            <span> Gửi giới thiệu</span>
          </Button>
        </div>
        <div className={cx("copy-link")}>
          <h2 className={cx("title")}>3. Chia sẻ trên mạng xã hội</h2>
          <p className={cx("text")}>
            Giới thiệu bạn bè bằng cách chia sẻ liên kết giới thiệu cá nhân của
            bạn với mạng của bạn:
          </p>

          <div className={cx("form-label")}>Đến:</div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={cx("link-input")}
          />
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={cx("message-input")}
            rows="5"
          />

          <Button primary onClick={handleSubmit}>
            <span className={cx("link-text")}>Gửi lời mời!</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Referrals;
