import classNames from "classnames/bind";
import styles from "./FourPads.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

const cx = classNames.bind(styles);

function FourPads({ title, data }) {
  return (
    <div className={cx("wrapper")}>
      <div className={cx("title-container")}>
        <h2 className={cx("title")}>{title}</h2>
      </div>
      <div className={cx("center")}>
        <div className={cx("container")}>
          {data.map((item) => (
            <div
              className={cx(item.className)}
              key={item.className}
              style={{ cursor: "default" }}
            >
              <div className={cx("itemContainer")}>
                <div className={cx("imageContainer")}>
                  <img
                    src={item.icon}
                    className={cx("image")}
                    alt={item.name}
                  />
                </div>
                <div className={cx("item-text-container")}>
                  <h2 className={cx("item-title")}>{item.name}</h2>
                  <p className={cx("item-subtitle")}>{item.description}</p>
                </div>
                {item?.link && (
                  <div>
                    <FontAwesomeIcon
                      icon={faCircleArrowRight}
                      inverse
                      size="2x"
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FourPads;
