import React, { useState } from "react";

import "./styles.css";

const Switch = () => {
  const [isChecked, setIsChecked] = useState(null);

  const _handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <label>
      <input
        checked={isChecked}
        onChange={_handleChange}
        className="switch"
        type="checkbox"
      />
      <div>
        <div></div>
      </div>
    </label>
  );
};

export default Switch;
