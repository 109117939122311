import { useRef, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Radios from "~/components/Radios";

const cx = classNames.bind(styles);

const ThirdStep = ({ info, setInfo }) => {
  const handleClickRadio = (item) => {
    setInfo((prev) => ({
      ...prev,
      place: item.value,
    }));
  };
  const thirdRef = useRef(null);

  useEffect(() => {
    thirdRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className={cx("section")} ref={thirdRef}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Vị trí phun?</h2>
      </div>
      <div className={cx("right")}>
        <Radios
          list={numberOfDevices}
          handleClickItem={handleClickRadio}
          value={info.place}
        />
      </div>
    </div>
  );
};
export default ThirdStep;

const numberOfDevices = [
  {
    id: 1,
    name: "Trong nhà",
    value: "Trong nhà",
  },
  {
    id: 2,
    name: "Ngoài trời",
    value: "Ngoài trời",
  },
  {
    id: 3,
    name: "Cả trong nhà và ngoài trời",
    value: "Cả trong nhà và ngoài trời",
  },
];
