import {
  SANITIZATION_DATE,
  SANITIZATION_TIME,
  SANITIZATION_NOTEPROS,
  SANITIZATION_CONTACT,
  RESET_SANITIZATION,
  SANITIZATION_SIZE,
  SANITIZATION_PLACE,
} from "../constants";

const initialState = {
  size: "1 - 70m2",
  price: 550000,
  place: "",
  date: "",
  time: "",
  notePros: "",
  contact: "",
  discount: 0,
};

export const sanitization = (state = initialState, action) => {
  switch (action.type) {
    case SANITIZATION_SIZE:
      return {
        ...state,
        size: action.payload.size,
        price: action.payload.price,
      };
    case SANITIZATION_PLACE:
      return {
        ...state,
        place: action.payload,
      };
    case SANITIZATION_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case SANITIZATION_TIME:
      return {
        ...state,
        time: action.payload,
      };

    case SANITIZATION_NOTEPROS:
      return {
        ...state,
        notePros: action.payload,
      };
    case SANITIZATION_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case RESET_SANITIZATION:
      return initialState;
    default:
      return state;
  }
};
