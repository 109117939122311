import React from "react";
import { useNavigate } from "react-router-dom";

// import BottomButton from "../../components/utils/BottomButton";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { airconTotal } from "~/redux/actions/aircon";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
const cx = classNames.bind(styles);

const FirstStep = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const aircon = useSelector((state) => state.aircon);
  useEffect(() => {
    const res = aircon.items.reduce((total, currentValue) => {
      // console.log(total);
      return total + currentValue.price * currentValue.quantity;
    }, 0);
    dispatch(airconTotal(res));
  }, [aircon.items, dispatch]);

  const numberOfAirconMaintains = aircon.items
    .filter((item) => item.service === "AirconMaintain")
    ?.reduce((total, currentValue) => {
      return total + currentValue.quantity;
    }, 0);

  const numberOfAirconAssembles = aircon.items
    .filter((item) => item.service === "AirconAssemble")
    ?.reduce((total, currentValue) => {
      return total + currentValue.quantity;
    }, 0);

  console.log("aircon ==>>", aircon);

  return (
    <div className={cx("section")}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>
          Xin vui lòng chọn loại và số lượng điều hòa.
        </h2>
      </div>
      <div className={cx("right")}>
        <div
          style={{
            border: "1px solid red",
            padding: "8px 12px",
            borderRadius: 4,
            cursor: "pointer",
            display: "flex",
          }}
          onClick={() => navigate("/aircon/airconMaintain")}
        >
          <span style={{ flex: 1 }}>Chọn loại máy</span>
          {numberOfAirconMaintains ? (
            <span style={{ color: "red" }}>x {numberOfAirconMaintains}</span>
          ) : (
            ">"
          )}
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
