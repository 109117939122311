import classNames from "classnames/bind";

import { Link } from "react-router-dom";

import styles from "./SmallHeader.module.scss";

const cx = classNames.bind(styles);

function SmallHeader() {
  return (
    <header className={cx("wrapper")}>
      <div className={cx("inner")}>
        <Link to="/">
          <h2 className={cx("logo-link")}>Uhomes</h2>
        </Link>
      </div>
    </header>
  );
}

export default SmallHeader;
