import { combineReducers } from "redux";
import { auth } from "./auth";

import { moving } from "./moving";

import { modals } from "./modals";

import { internet } from "./internet";
import { camera } from "./camera";

import { cleaning } from "./cleaning";
import { cleanOther } from "./cleanOther";
import { aircon } from "./aircon";

import { painting } from "./painting";
import { sanitization } from "./sanitization";
import referralReducer from "./referral";

const Reducers = combineReducers({
  auth,
  moving,
  modals,
  internet,
  painting,
  cleaning,
  cleanOther,
  aircon,
  sanitization,
  camera,
  referral: referralReducer,
});

export default Reducers;
