import classNames from "classnames/bind";
import styles from "./Columns.module.scss";

const cx = classNames.bind(styles);

function Columns({ item, bg }) {
  return (
    <div
      className={cx("container")}
      style={
        bg && {
          backgroundColor: bg,
          paddingTop: "60px",
          paddingBottom: "60px",
          borderTopLeftRadius: "32px",
          borderTopRightRadius: "32px",
        }
      }
    >
      <div className={cx("wrapper")}>
        <div className={cx("heading")}>
          <h2 className={cx("title")}>{item.title}</h2>
          <p className={cx("sub-title")}>{item.text}</p>
        </div>
        <div className={cx("intro-list")}>
          {item.intros.map((item) => (
            <div
              className={cx("intro-item")}
              key={item.id}
              style={
                bg && {
                  backgroundColor: "white",
                  border: "none",
                }
              }
            >
              <div className={cx("icon-item")}>{item.icon}</div>

              <div className={cx("intro-item-text")}>
                <h2 className={cx("intro-item-title")}>{item.title}</h2>
                <ul className={cx("item-subtitle-list")}>
                  <li className={cx("item-subtitle")}>
                    <p className={cx("intro-item-subtitle")}>{item.subtile1}</p>
                  </li>
                  {item.subtile2 && (
                    <li className={cx("item-subtitle")}>
                      <p className={cx("intro-item-subtitle")}>
                        {item.subtile2}
                      </p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Columns;
