import {
  SET_CURRENT_LOCATION,
  SET_NOTE_DRIVER_MODAL,
  SET_PRICE_DETAILS_MODAL,
  SET_SUCCESS_MODAL,
  SET_PHONE_NUMBER_MODAL,
  SET_NUMBER_OF_NOTIFICATIONS,
} from "../constants";

const initialState = {
  currentLocation: "",
  notifications: [],
  successModal: false,
  priceModal: false,
  noteDriverModal: false,
  phoneModal: false,
};

export const modals = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    case SET_NUMBER_OF_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case SET_SUCCESS_MODAL:
      return {
        ...state,
        successModal: action.payload,
      };
    case SET_PRICE_DETAILS_MODAL:
      return {
        ...state,
        priceModal: action.payload,
      };
    case SET_NOTE_DRIVER_MODAL:
      return {
        ...state,
        noteDriverModal: action.payload,
      };
    case SET_PHONE_NUMBER_MODAL:
      return {
        ...state,
        phoneModal: action.payload,
      };
    default:
      return state;
  }
};
