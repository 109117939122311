import React, { useState } from "react";
import styles from "./ForgotPassword.module.scss";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { resetEmail } from "../../../redux/actions/auth"; // Đảm bảo rằng bạn đã import đúng file hành động

const cx = classNames.bind(styles);

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    try {
      await dispatch(resetEmail(email));
      setMessage(
        "Email đặt lại mật khẩu đã được gửi. Vui lòng kiểm tra hộp thư đến của bạn."
      );
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={cx("main")}>
      {message ? (
        <div className={cx("content")}>
          <h2 className={cx("heading")}>Thành công</h2>
          <p className={cx("success-message")}>{message}</p>
          <Link className={cx("link")} to="/login">
            Quay lại trang đăng nhập
          </Link>
        </div>
      ) : (
        <>
          <h2 className={cx("heading")}>Quên mật khẩu</h2>
          <div className={cx("content")}>
            <div className={cx("title-container")}>
              <span>Nhập email của bạn để đặt lại mật khẩu</span>
            </div>
            <ul>
              <li>
                <input
                  type="email"
                  name="usr_email"
                  id="usr_email"
                  placeholder="Địa chỉ email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </li>
              {error && (
                <li>
                  <p className={cx("error-message")}>{error}</p>
                </li>
              )}
              <li>
                <Button primary onClick={handleResetPassword}>
                  Gửi email đặt lại mật khẩu
                </Button>
              </li>
            </ul>
          </div>
          <div className={cx("bottom")}>
            <p className="tc mt3">
              Nhớ mật khẩu?{" "}
              <Link className={cx("link")} to="/login">
                Đăng nhập
              </Link>
              .
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default ForgotPassword;
