import React from "react";
import MapSearch from "./MapSearch";

function Search({ setValue }) {
  return <MapSearch setValue={setValue} />;
}

export default Search;
export const removeAccents = (str) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};
