import { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { publicRoutes, protectedRoutes } from "~/routes"; // Thêm protectedRoutes
import DefaultLayout from "~/layouts/DefaultLayout";

import ScrollToTop from "~/hooks/scrollToTop";
import { useDispatch } from "react-redux";
import { userAuthStateListener } from "./redux/actions/auth";
import ProtectedRoute from "./ProtectedRoute"; // Nhập ProtectedRoute

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userAuthStateListener());
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {publicRoutes.map((route, index) => {
          const Page = route.component;
          let Layout = DefaultLayout;

          if (route.layout) {
            Layout = route.layout;
          } else if (route.layout === null) {
            Layout = Fragment;
          }
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <Layout>
                  <Page />
                </Layout>
              }
            />
          );
        })}
        {protectedRoutes.map((route, index) => {
          const Page = route.component;
          let Layout = DefaultLayout;

          if (route.layout) {
            Layout = route.layout;
          } else if (route.layout === null) {
            Layout = Fragment;
          }
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute>
                  <Layout>
                    <Page />
                  </Layout>
                </ProtectedRoute>
              }
            />
          );
        })}
      </Routes>
    </Router>
  );
}

export default App;
