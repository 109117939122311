import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Radios from "~/components/Radios";

const cx = classNames.bind(styles);
const ThirdStep = ({ internet, setInternet }) => {
  const handleClickItem = (item) => {
    setInternet((prev) => ({ ...prev, type: item.value }));
  };

  return (
    <div className={cx("section")}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>
          Vui lòng ước tính diện tích cần dọn dẹp.
        </h2>
      </div>
      <div className={cx("right")}>
        <Radios
          value={internet.type}
          list={list}
          handleClickItem={handleClickItem}
        />
      </div>
    </div>
  );
};
export default ThirdStep;

const list = [
  {
    id: 1,
    title: "2 giờ - 55m2/ 2 phòng",
    value: "2h",
  },
  {
    id: 2,
    title: "3 giờ - 85m2/ 3 phòng",
    value: "3h",
  },
  {
    id: 3,
    title: "4 giờ - 105m2/ 4 phòng",
    value: "4h",
  },
];
