import React from "react";
import SeventhStep from "./SeventhStep";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import SmallLayout from "~/components/SmallLayout";
const cx = classNames.bind(styles);

function MovingSize() {
  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <div>
            MovingSize
            <SeventhStep />
          </div>{" "}
        </div>{" "}
      </div>
    </SmallLayout>
  );
}

export default MovingSize;
