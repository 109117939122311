import classNames from "classnames/bind";
import Cards from "../../Cards/Cards";
import styles from "./TextAndImage.module.scss";

import AllText from "~/components/AllText";

const cx = classNames.bind(styles);

function TextAndImage({ item, bottom }) {
  return (
    <div className={cx("container")}>
      <div className={cx("mainSection")}>
        <div className={cx("left-mainSection")}>
          <AllText item={item} bottom={bottom} />
        </div>
        <div className={cx("right-mainSection")}>
          <Cards item={item.media} />
        </div>
      </div>
    </div>
  );
}

export default TextAndImage;
