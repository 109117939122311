import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";
import ThirdStep from "./ThirdStep";
import SeventhStep from "./SeventhStep";
import SmallLayout from "~/components/SmallLayout";

const cx = classNames.bind(styles);

function SanitizationSteps() {
  const [info, setInfo] = useState({
    address: "",
    type: "",
    date: "",
    time: "",
    place: "",
    note: "",
  });
  console.log(info);
  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <h2>Đặt dịch vụ phun khử trùng</h2>
          <FirstStep info={info} setInfo={setInfo} />
          {info.address && <SecondStep info={info} setInfo={setInfo} />}

          {info.type && <ThirdStep info={info} setInfo={setInfo} />}

          {info.place && <FourthStep info={info} setInfo={setInfo} />}

          {info.date && <FifthStep info={info} setInfo={setInfo} />}

          {info.time && <SixthStep info={info} setInfo={setInfo} />}

          {info.time && <SeventhStep info={info} setInfo={setInfo} />}
        </div>
      </div>
    </SmallLayout>
  );
}

export default SanitizationSteps;
