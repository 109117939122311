import { useState } from "react";
import Inputs from "./Inputs";
import Input from "react-phone-number-input/input";
import { setUpRecaptha } from "../../../hooks/setUpRecaptha";
import classNames from "classnames/bind";
import styles from "./Phone.module.scss";
import Button from "../../../components/Button/Button";
import "react-phone-number-input/style.css";
import { Link, useNavigate } from "react-router-dom";
import { addUserToFirestore } from "../../../redux/actions/auth";

const cx = classNames.bind(styles);

export const PhoneNumber = ({ handleClose }) => {
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Thêm state này
  const navigate = useNavigate();

  const getOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (number === "" || number === undefined) {
      return setError("Vui lòng nhập số điện thoại hợp lệ!");
    }
    setIsSubmitting(true); // Bắt đầu quá trình xử lý
    try {
      const response = await setUpRecaptha(number);
      setResult(response);
      setFlag(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false); // Kết thúc quá trình xử lý
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null) return;
    try {
      const userCredential = await result.confirm(otp);
      const user = userCredential.user;
      await addUserToFirestore(user); // Thêm người dùng vào Firestore

      navigate("/");
      handleClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className={cx("container")}>
      <div className={cx("content")}>
        {error && <span className={cx("error")}>{error}</span>}
        <form
          onSubmit={getOtp}
          className={cx("form")}
          style={{ display: !flag ? "flex" : "none" }}
        >
          <h2 className={cx("title")}>Số điện thoại</h2>
          <div className={cx("phoneContainer")}>
            <Input
              defaultCountry="VN"
              value={number}
              onChange={setNumber}
              placeholder="Nhập số điện thoại"
              className={cx("phone-input")}
              autoFocus
              withCountryCallingCode
              country="VN"
              international
            />
          </div>
          <div id="recaptcha-container"></div>
          <div className={cx("button")}>
            <Button primary type="submit" disabled={isSubmitting || !!result}>
              Gửi mã xác thực
            </Button>
          </div>
        </form>

        <form
          onSubmit={verifyOtp}
          className={cx("form")}
          style={{ display: flag ? "flex" : "none" }}
        >
          <h2 className={cx("title")}>Nhập mã gửi đến điện thoại</h2>
          <Inputs
            type="otp"
            placeholder="Nhập OTP"
            setValue={setOtp}
            autoFocus
          />
          <div className={cx("button")}>
            <Button primary type="submit">
              Tiếp tục
            </Button>
          </div>
        </form>
      </div>
      <div
        className={cx("bottom")}
        style={{ display: !flag ? "flex" : "none" }}
      >
        <Link to="/login">
          <span className={cx("link")}>Đăng nhập với email</span>
        </Link>
      </div>
    </div>
  );
};

export default PhoneNumber;
