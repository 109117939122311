import React from "react";
import classNames from "classnames/bind";
import styles from "./Cards.module.scss";

const cx = classNames.bind(styles);

const Cards = () => {
  return (
    <div className={cx("graphics-container")}>
      <div className={cx("svg-polyfill", "svg-polyfill-active")}>
        <div
          className={cx("svg-polyfill-render", "bg-beige")}
          style={{
            width: "44.476016%",
            height: "83.07263%",
            top: "17.213524%",
            left: "69.153473%",
            borderRadius: "24px",
            transformOrigin: "0px 0px",
            transform: "rotate(-5.33294deg)",
          }}
        ></div>
        <div
          className={cx("svg-polyfill-render")}
          style={{
            width: "44.476016%",
            height: "81.800716%",
            top: "2.32585%",
            left: "46.26173%",
            borderRadius: "24px",
            transformOrigin: "0px 0px",
            transform: "rotate(1.66606deg)",
          }}
        >
          <video
            className={cx("svg-image-video-video")}
            width="100%"
            height="100%"
            autoPlay
            loop
            muted // Thêm thuộc tính muted
            crossOrigin="anonymous"
            playsInline
            style={{ borderRadius: "24px" }} // Đảm bảo video được bo tròn
          >
            <source
              src="https://res.cloudinary.com/updater-marketing/video/upload/q_auto/v1651265969/updater-dot-com/updater-shadows.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div
          className={cx("svg-polyfill-render", "bg-blue")}
          style={{
            width: "44.476016%",
            height: "83.07263%",
            top: "14.276154%",
            left: "19.342333%",
            borderRadius: "24px",
            transformOrigin: "0px 0px",
            transform: "rotate(-5.81396deg)",
          }}
        ></div>
        <div
          className={cx("svg-polyfill-render")}
          style={{
            width: "44.476016%",
            height: "83.07263%",
            top: "15.712093%",
            left: "0%",
            borderRadius: "24px",
            transformOrigin: "0px 0px",
            transform: "rotate(-15deg)",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "50% center",
              borderRadius: "24px 24px 0 0", // Bo tròn 2 góc dưới
              backgroundImage: `url("https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/homepage/homepage-hero-people.png")`,
            }}
          ></div>
        </div>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 763 559"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <rect
            className={cx("beige")}
            x="527.641"
            y="96.2236"
            width="339.352"
            height="464.376"
            rx="24"
            transform="rotate(-5.33294 527.641 96.2236)"
            fill="#F1F1F1"
          ></rect>

          <rect
            className={cx("blue")}
            x="147.582"
            y="79.8037"
            width="339.352"
            height="464.376"
            rx="24"
            transform="rotate(-5.81396 147.582 79.8037)"
            fill="#F1F1F1"
          ></rect>
        </svg>
      </div>
    </div>
  );
};

export default Cards;
