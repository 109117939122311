import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setReferralCode } from "../../../redux/actions/referral";

const ReferralPage = () => {
  const { referralCode } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (referralCode) {
      dispatch(setReferralCode(referralCode));
      navigate("/");
    }
  }, [referralCode, dispatch, navigate]);

  return null;
};

export default ReferralPage;
