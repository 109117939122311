import {
  PAINTING_DATE,
  PAINTING_TIME,
  PAINTING_NOTEPROS,
  PAINTING_CONTACT,
  RESET_PAINTING,
} from "../constants";

const initialState = {
  date: "",
  time: "",
  notePros: "",
  contact: "",
};

export const painting = (state = initialState, action) => {
  switch (action.type) {
    case PAINTING_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case PAINTING_TIME:
      return {
        ...state,
        time: action.payload,
      };

    case PAINTING_NOTEPROS:
      return {
        ...state,
        notePros: action.payload,
      };
    case PAINTING_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case RESET_PAINTING:
      return initialState;
    default:
      return state;
  }
};
