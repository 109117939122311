import classNames from "classnames/bind";
import styles from "./Internet.module.scss";
import TextAndImage from "~/components/TextAndImage/GeneralTIandIT";
import Columns from "~/components/Columns";
import Providers from "~/components/Providers";
import Sliders from "~/components/Sliders";
import Ti from "~/components/TextAndImage/Ti";
import { ListIcon, QualityListing, RealTimeIcon } from "~/components/Icons";
import Three from "~/components/TextAndImage/Three";
import Review from "~/components/Review";
import Button from "~/components/Button/Button";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

function Internet() {
  const navigate = useNavigate();

  const handleAction = () => navigate("/internet/steps");
  const Bottom = () => (
    <Button onClick={handleAction} primary>
      Nhận báo giá miễn phí
    </Button>
  );

  return (
    <div className={cx("main")}>
      <TextAndImage item={itemTextAndImage} bottom={Bottom} />
      <Providers item={itemProviders} />
      <Columns item={itemColumns1} bg="#e0ecfe" />
      <Ti item={itemPartner} />
      <Three item={itemCustomer} />
      <Review item={itemReview} />
      <Columns item={itemColumn2} />
      <Sliders item={itemSliders} />
    </div>
  );
}

export default Internet;

const itemReview = {
  pre: "WHAT MOVERS ARE SAYING",
  content:
    "“I work from home so not having wifi for a week wasn’t an option. I picked my plan and scheduled my installation with just a few clicks.”",
  author: "Bryan J.",
  place: "From Boston, MA to Cambridge, MA.",
};

const itemTextAndImage = {
  title: "So sánh và chọn lựa gói cước internet cho ngôi nhà của bạn",
  text: "Tìm gói internet hoàn hảo cho bạn với các nhà cung cấp lớn nhất của quốc gia. Dễ dàng so sánh các gói, giá cả và nhà cung cấp có sẵn trong khu vực của bạn.",
  media: {
    imageUrl:
      "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/homepage/homepage-hero-people.png",
  },
};

const itemProviders = {
  title: "So sánh các gói từ các nhà cung cấp internet và truyền hình gần bạn",
  text: "",
  providers: [
    "https://fpt-fti.com/wp-content/uploads/2020/12/FPT_logo.png",
    "https://via.org.vn/datafiles/setone/1620884668_Logo_Viettel-Telecom.png",
    "https://vnpttelecom.com.vn/wp-content/uploads/2022/06/cropped-Logo-VNPT-TP-HCM-1.png",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/CMC_logo_2018.png/1200px-CMC_logo_2018.png",
  ],
};

const itemColumns1 = {
  title: "Dễ dàng tìm được gói internet và TV hoàn hảo",
  text: "So sánh các lựa chọn từ các nhà cung cấp có sẵn tại nhà của bạn một cách nhanh chóng và dễ dàng.",
  intros: [
    {
      id: 1,
      title: "Chuyên gia internet và truyền hình",
      subtile1:
        "Chúng tôi giúp hàng trăm nghìn người tìm được các gói cước Internet và TV hoàn hảo mỗi năm.",
      icon: <QualityListing />,
    },
    {
      id: 2,
      title: "Dịch vụ đầy đủ",
      subtile1:
        "Lắp đặt Internet & TV, Chuyển nhà, Dọn dẹp nhà, Sơn tường chỉ với vài click.",
      icon: <ListIcon />,
    },
    {
      id: 3,
      title: "Cập nhật liên tục",
      subtile1:
        "Danh sách tin đăng được hàng ngàn chủ nhà và môi giới cho thuê đăng lên mỗi giờ.",
      icon: <RealTimeIcon />,
    },
  ],
};

const itemColumn2 = {
  title: "Khám phá thêm các dịch vụ Uhomes",
  text: "Tiết kiệm thời gian, tiền bạc và những cơn đau đầu trong lần di chuyển tiếp theo của bạn với các dịch vụ của Uhomes.",
  intros: [
    {
      id: 1,
      title: "Dịch vụ chuyển nhà",
      subtile1:
        "Di chuyển toàn bộ ngôi nhà của bạn hoặc chỉ những món đồ nặng nhất của bạn và lên lịch vào cùng ngày hoặc trước vài tuần.",
      icon: <QualityListing />,
    },
    {
      id: 2,
      title: "Insurance",
      subtile1:
        "Transfer your existing policy or find a new one that's perfectly tailored to your unique needs. Let's get started.",
      icon: <ListIcon />,
    },
    {
      id: 3,
      title: "Cập nhật liên tục",
      subtile1:
        "Danh sách tin đăng được hàng ngàn chủ nhà và môi giới cho thuê đăng lên mỗi giờ.",
      icon: <RealTimeIcon />,
    },
  ],
};

const itemCustomer = {
  pre: "",
  title: "Hàng trăm lựa chọn, một kế hoạch hoàn hảo",
  text: "Hãy xem các đánh giá chuyên sâu về nhà cung cấp của chúng tôi và tự tin mua sắm khi biết rằng bạn đã thực hiện nghiên cứu của mình.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/q_auto,f_auto/Man%20working%20from%20home%20on%20computer%20laptop.jpg",
};

const itemPartner = {
  pre: "",
  title: "Tự tin lựa chọn",
  text: "Hãy xem các đánh giá chuyên sâu về nhà cung cấp của chúng tôi và tự tin mua sắm khi biết rằng bạn đã thực hiện nghiên cứu của mình.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/q_auto,f_auto/Woman%20scrolling%20on%20phone%20at%20breakfast%20table.jpg",
};

const itemSliders = {
  title: "Internet and TV tips",
  text: "Chuyển đổi nhà cung cấp và không biết bắt đầu từ đâu? Chúng tôi có thể giúp.",
  list: [
    {
      id: 1,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617737158/shutterstock_720994654/shutterstock_720994654.jpg?_i=AA",
      title: "Create the Ultimate Home Office Setup in 5 Steps",
      description: "ưtgeargdsfgdsfg",
    },
    {
      id: 2,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/w_1024,h_683/f_auto,q_auto/v1603519436/calculator-desk_how-to-save-on-cable-internet-and-phone/calculator-desk_how-to-save-on-cable-internet-and-phone.jpg?_i=AA",
      title: "How to Save on Internet, Cable, and Phone",
      description:
        "Moving is expensive and knowing how to save on your internet, cable, and phone bills can really help balance out that spending. Curious about the best ways to save? Turns out, it’s not such a challenge once you know how. Here are 11 of our tried-and-true techniques for how you can save on internet, cable, and phone while still getting the most out of your monthly payments.",
    },
    {
      id: 3,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1619369585/family-sitting-on-sofa-i-1/family-sitting-on-sofa-i-1.jpg?_i=AA",
      title: "Moving While Expecting Kids? Be Sure to Set up TV and Internet",
      description:
        "Of all the things you deal with as a new parent during a household move, your belongings may be the most obvious. But what about services? When it comes to TV and internet, it pays to plan ahead and make sure you have service for every step of your journey. Here’s how to ensure you’re prepared to tackle parenthood, especially in your new home.",
    },
  ],
};
