import classNames from "classnames/bind";
import styles from "./Providers.module.scss";

const cx = classNames.bind(styles);

const Providers = ({ item }) => {
  return (
    <div className={cx("container")}>
      <div className={cx("wrapper")}>
        <h3 className={cx("title")}>{item.title}</h3>
        <p className={cx("sub-title")}>{item.text}</p>
        <div className={cx("list")}>
          {item.providers.map((item) => (
            <div className={cx("imageWrapper")} key={item}>
              <img src={item} alt={item} className={cx("image")} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Providers;
