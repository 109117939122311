import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "./calendar.css";

const cx = classNames.bind(styles);

const FifthStep = ({ info, setInfo }) => {
  const [value, onChange] = useState(info.date);
  console.log("hom nay", new Date());
  useEffect(() => {
    setInfo((prev) => ({ ...prev, date: value }));
  }, [value, setInfo]);
  return (
    <div className={cx("section")}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Vui lòng chọn ngày dọn dẹp.</h2>
      </div>
      <div className={cx("right")}>
        <Calendar
          next2Label={null}
          prev2Label={null}
          minDate={new Date()}
          locale="vi"
          onChange={onChange}
          value={value}
          // showNeighboringMonth={false}
        />
      </div>
    </div>
  );
};
export default FifthStep;

export const internetTVPurposes = [
  {
    name: "Các kênh và tin tức địa phương",
    value: "news&local",
  },
  {
    name: "Kênh giải trí, đời sống & trẻ em",
    value: "entertainment",
  },
  {
    name: "Kênh thể thao & cao cấp",
    value: "sports",
  },
  {
    name: "Kênh tiếng anh",
    value: "foreigner",
  },
];
