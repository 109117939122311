import {
  CLEANING_PACKAGE,
  CLEANING_EQUIPMENT,
  CLEANING_DISCOUNT_TYPE,
  CLEANING_DATE,
  CLEANING_TIME,
  CLEANING_NOTE_PROS,
  CLEANING_CONTACT,
  RESET_CLEANING,
} from "../constants";

const initialState = {
  package: "85m2 / 3 phòng",
  equipment: false,
  discount: "",
  price: "200000",
  date: "",
  time: "",
  notePros: "",
  contact: "",
};

export const cleaning = (state = initialState, action) => {
  switch (action.type) {
    case CLEANING_PACKAGE:
      return {
        ...state,
        package: action.payload.note,
        price: action.payload.price,
      };

    case CLEANING_EQUIPMENT:
      return {
        ...state,
        equipment: action.payload,
      };

    case CLEANING_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case CLEANING_TIME:
      return {
        ...state,
        time: action.payload,
      };

    case CLEANING_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };

    case CLEANING_NOTE_PROS:
      return {
        ...state,
        notePros: action.payload,
      };

    case CLEANING_DISCOUNT_TYPE:
      return {
        ...state,
        discount: action.payload,
      };

    case RESET_CLEANING:
      return initialState;
    default:
      return state;
  }
};
