import React from "react";
import styles from "./Dashboard.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

function Dashboard() {
  return (
    <div className={cx("dashboardContainer")}>
      <div className={styles.content}>
        <h1>Dashboard</h1>
        {/* Nội dung trang Dashboard */}
        <p>Chào mừng bạn đến với trang quản lý.</p>
        {/* Bạn có thể thêm các thành phần khác tại đây */}
      </div>
    </div>
  );
}

export default Dashboard;
