import React from "react";
import classNames from "classnames/bind";
import styles from "./Input.module.scss";

const cx = classNames.bind(styles);

function Inputs({
  placeholder,
  title,
  value,
  setValue,
  bottom,
  number,
  type,
  error,
  setError,
  disabled,
  autoFocus,
}) {
  return (
    <div className={cx("wrapper")}>
      <div className={cx("title")}>{title}</div>
      <div className={cx("container")}>
        <input
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          className={cx("input")}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          spellCheck={false}
          onKeyPress={(event) => {
            error && setError("");
            if (number) {
              !/[0-9]/.test(event.key) && event.preventDefault();
            }
          }}
          autoFocus={autoFocus}
        />
      </div>
      {bottom && <div className={cx("bottom")}>{bottom}</div>}
    </div>
  );
}

export default Inputs;
