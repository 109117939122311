import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Radios from "~/components/Radios";

const cx = classNames.bind(styles);

const SecondStep = ({ info, setInfo }) => {
  const handleClickRadio = (item) => {
    setInfo((prev) => ({
      ...prev,
      type: item.value,
    }));
  };

  return (
    <div className={cx("section")}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Hãy chọn nhu cầu của bạn</h2>
      </div>
      <div className={cx("right")}>
        <Radios
          list={numberOfDevices}
          handleClickItem={handleClickRadio}
          value={info.type}
        />
      </div>
    </div>
  );
};
export default SecondStep;

const numberOfDevices = [
  {
    id: 1,
    name: "Internet",
    value: "Internet",
  },
  {
    id: 2,
    name: "Internet & Truyền hình",
    value: "combo",
  },
];
