import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Checkboxs from "~/components/Checkboxs";

const cx = classNames.bind(styles);

const FourthStep = ({ info, setInfo }) => {
  const plus = (value) => {
    setInfo((state) => ({
      ...state,
      purposeOfTV: [...state.purposeOfTV, value],
    }));
  };

  const minus = (value) => {
    setInfo((state) => ({
      ...state,
      purposeOfTV: state.purposeOfTV.filter((item) => item !== value),
    }));
  };

  return (
    <div className={cx("section")}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Bạn dùng TV để? </h2>
      </div>
      <div className={cx("right")}>
        <Checkboxs
          data={info.purposeOfTV}
          list={internetTVPurposes}
          minus={minus}
          plus={plus}
        />
      </div>
    </div>
  );
};
export default FourthStep;

export const internetTVPurposes = [
  {
    name: "Các kênh và tin tức địa phương",
    value: "news&local",
  },
  {
    name: "Kênh giải trí, đời sống & trẻ em",
    value: "entertainment",
  },
  {
    name: "Kênh thể thao & cao cấp",
    value: "sports",
  },
  {
    name: "Kênh tiếng anh",
    value: "foreigner",
  },
];
