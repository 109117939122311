import React, { useState, useEffect } from "react";
import styles from "./Setting.module.scss";
import classNames from "classnames/bind";
import { doc, getDoc } from "firebase/firestore";
import { db, uploadImage } from "../../../config/firebase";
import AvatarUpload from "~/components/AvatarUpload";
import { useDispatch, useSelector } from "react-redux";
import { updateCurentUserData } from "../../../redux/actions/auth";

const cx = classNames.bind(styles);

function Setting() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photoUrl, setPhotoUrl] = useState(
    user?.photoURL ||
      "https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png"
  );
  const [photoFile, setPhotoFile] = useState(null);
  console.log("Setting====>", user);
  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        setName(userData.displayName || "");
        setEmail(userData.email || "");
        setPhone(userData.phoneNumber || "");
        setPhotoUrl(userData.photoURL || "");
      };
      fetchData();
    }
  }, [user]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        let photoURL = photoUrl;
        if (photoFile) {
          photoURL = await uploadImage(photoFile, user.uid);
        }
        dispatch(updateCurentUserData(photoURL, name, email, phone));

        setPhotoUrl(photoURL);
        alert("Thông tin đã được cập nhật!");
      }
    } catch (error) {
      alert("Có lỗi xảy ra: " + error.message);
    }
  };

  return (
    <div className={cx("profilePage")}>
      <form className={cx("formContainer")} onSubmit={handleUpdate}>
        <AvatarUpload
          photoUrl={photoUrl}
          setPhotoFile={setPhotoFile}
          setPhotoUrl={setPhotoUrl}
        />
        <div className={cx("formGroup")}>
          <label className={cx("label")} htmlFor="name">
            Họ tên:
          </label>
          <input
            className={cx("input")}
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className={cx("formGroup")}>
          <label className={cx("label")} htmlFor="email">
            Email:
          </label>
          <input
            className={cx("input")}
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={user?.methodRegister === "email" ? true : false}
          />
        </div>
        <div className={cx("formGroup")}>
          <label className={cx("label")} htmlFor="phone">
            Số điện thoại:
          </label>
          <input
            className={cx("input")}
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={user?.methodRegister === "phone" ? true : false}
          />
        </div>
        <button type="submit" className={cx("updateButton")}>
          Cập nhật thông tin
        </button>
      </form>
    </div>
  );
}

export default Setting;
