import classNames from "classnames/bind";
import Button from "~/components/Button";
import styles from "./Sliders.module.scss";

import { Swiper, SwiperSlide } from "swiper/react";

import { useRef } from "react";
import "swiper/swiper.scss";

import SlideUp from "./SlideUp";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

function Sliders({ item, movingTips }) {
  const swiperRef = useRef(null);

  const navigate = useNavigate();

  return (
    <div className={cx("container")}>
      <div className={cx("wrapper")}>
        <h2 className={cx("title")}>{item.title} </h2>
        {item.text && <p className={cx("text")}>{item.text} </p>}
        <div className={cx("slider-container")}>
          <Swiper
            spaceBetween={16}
            slidesPerView={3}
            ref={swiperRef}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
              },
              991: {
                slidesPerView: 3,
              },
            }}
          >
            {item.list.map((item) => (
              <SwiperSlide key={item.id}>
                <SlideUp item={item} movingTips={movingTips} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={cx("navigation")}>
          <div
            className={cx("btn")}
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="2x" />
          </div>
          <div
            className={cx("btn")}
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <FontAwesomeIcon icon={faArrowAltCircleRight} size="2x" />
          </div>
        </div>
        <Button
          outline
          className={cx("value-btn")}
          onClick={() => {
            movingTips
              ? navigate("/kinh-nghiem-chuyen-nha")
              : navigate("/tin-tuc-can-biet");
          }}
        >
          Đọc thêm
        </Button>
      </div>
    </div>
  );
}

export default Sliders;
