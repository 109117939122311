import { db } from "../../config/firebase";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { USER_STATE_CHANGE } from "../constants";
import { doc, setDoc, onSnapshot, updateDoc, getDoc } from "firebase/firestore";

const auth = getAuth();

export const userAuthStateListener = () => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(getCurrentUserData());
    } else {
      dispatch({ type: USER_STATE_CHANGE, currentUser: null, loaded: true });
    }
  });
};

export const getCurrentUserData = () => (dispatch) => {
  const docRef = doc(db, "users", auth.currentUser.uid);
  onSnapshot(docRef, (doc) => {
    return dispatch({
      type: USER_STATE_CHANGE,
      currentUser: doc.data(),
      loaded: true,
    });
  });
};

export const updateCurentUserData =
  (photoURL, displayName, email, phoneNumber) => (dispatch) => {
    const docRef = doc(db, "users", auth.currentUser.uid);
    updateDoc(docRef, {
      photoURL,
      displayName,
      email,
      phoneNumber,
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

export const login = (email, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((cred) => {
        resolve(cred);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const register = (email, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((cred) => {
        addUserToFirestore(cred.user);
        resolve(cred);
      })
      .catch((error) => {
        console.log(error.message);
        reject(error);
      });
  });

export const addUserToFirestore = async (user) => {
  const userDocRef = doc(db, "users", user.uid);

  const { uid, email, displayName, photoURL, phoneNumber } = user;
  const userDoc = await getDoc(userDocRef);
  if (!userDoc.exists()) {
    await setDoc(userDocRef, {
      uid,
      email,
      displayName,
      photoURL,
      phoneNumber,
    });
  }
};

export const confirmEmail = () => (dispatch) =>
  new Promise((resolve, reject) => {
    sendEmailVerification(auth.currentUser)
      .then((cred) => {
        resolve(cred);
      })
      .catch((error) => {
        console.log(error.message);
        reject(error);
      });
  });

export const logout = () => (dispatch) =>
  new Promise((resolve, reject) => {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful.");
      })
      .catch((error) => {
        console.log("An error happened.");
      });
  });

export const resetEmail = (email) => (dispatch) =>
  new Promise((resolve, reject) => {
    sendPasswordResetEmail(auth, email)
      .then((cred) => {
        resolve(cred);
      })
      .catch((error) => {
        console.log(error.message);
        reject(error);
      });
  });
