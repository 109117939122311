import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./ListInternet.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { internetPackage } from "~/redux/actions/internet";

import images from "~/assets/images";
import Button from "~/components/Button/Button";

const cx = classNames.bind(styles);

function Item({ item }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const dispatch = useDispatch();
  const internet = useSelector((state) => state.internet);

  const handleItem = (value) => {
    dispatch(internetPackage(value));
  };

  const renderProviderLogo = () => {
    if (item.provider === "Viettel") {
      return <img alt="logo" src={images.viettel} className={cx("logo")} />;
    } else if (item.provider === "FPT") {
      return <img alt="logo" src={images.fpt} className={cx("logo")} />;
    } else if (item.provider === "VNPT") {
      return <img alt="logo" src={images.vnpt} className={cx("logo")} />;
    }
  };
  console.log("item ==>>", item);
  return (
    <div
      className={cx("item")}
      style={{ border: item.name === internet.package && "2px solid red" }}
    >
      <div className={cx("details")}>
        <div className={cx("top")}>
          <div className={cx("imageContainer")}>{renderProviderLogo()}</div>
          <div className={cx("titleContainer")}>
            <span className={cx("title")}>{item.name}</span>
          </div>
          <div className={cx("radio-item")}>
            <svg
              className={cx("radio-dot")}
              width="22"
              height="22"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM10 6C7.79086 6 6 7.79086 6 10C6 12.2091 7.79086 14 10 14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6Z"
                fill={item.name === internet.package ? "#ff7800" : "#F3F4F8"}
              ></path>
            </svg>
          </div>
        </div>

        <div>
          <div className={cx("type")}>
            {/* <JustWifi width={30} height={30} fill="red" /> */}
            {/* {item.type === "Combo" && <TV width={40} height={40} fill="red" />} */}

            <span className={cx("typespan")}>
              Internet {item.type === "Combo" && "& Truyền hình"}
            </span>
          </div>
          <div className={cx("info")}>
            <div className={cx("info-column")}>
              <span className={cx("subTitle")}>Tốc độ tải lên đến</span>
              <span className={cx("accentspan")}>
                {item.bandwidth} <span className={cx("subTitle")}>Mbs</span>
              </span>
            </div>
            <div className={cx("info-column")}>
              <span className={cx("subTitle")}>Giá mỗi tháng</span>
              <span className={cx("accentspan")}>
                {item.priceMonthly}
                <span className={cx("subTitle")}>đ</span>
              </span>
            </div>
          </div>
          <Button
            primary
            style={{ width: 140, margin: "20px 0 0" }}
            onClick={() => handleItem(item)}
          >
            Chọn
          </Button>
        </div>
      </div>

      <div className={cx("more")}>
        <div
          className={cx("highlight")}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span className={cx("highlightspan")}>Xem thêm chi tiết</span>
          {/* <Ionicons
            name={!isExpanded ? "chevron-down-sharp" : "chevron-up-sharp"}
            size={24}
            color={Colors.blue}
          /> */}
        </div>
        {isExpanded && (
          <div>
            <div className={cx("tips")}>
              <span>Uhomes tips:</span>
              <span>Tốc độ này hỗ trợ sử dụng lên đến 11 thiết bị một lúc</span>
            </div>
            <span>Ưu đãi</span>
            <span>Tặng 2 tháng khi đăng ký 6 tháng</span>
            <span>Tặng 4 tháng khi đăng ký 12 tháng</span>
          </div>
        )}
      </div>
    </div>
  );
}
export default Item;
