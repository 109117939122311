import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Button from "~/components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import { useSelector } from "react-redux";

const cx = classNames.bind(styles);

const SeventhStep = ({ info }) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const internet = useSelector((state) => state.internet);

  return (
    <div className={cx("bottom")}>
      <div className={cx("bottom-row")}>
        <h3 className={cx("bottom-title")}>Tiền hoàn lại dự kiến</h3>
        <span className={cx("bottom-number")}>300.000đ</span>
      </div>
      <div className={cx("bottom-note")} onClick={() => setVisible(!visible)}>
        Chuyển khoản sau khi ký hợp đồng.
        <FontAwesomeIcon icon={faCircleInfo} />
      </div>

      <Modal
        modalShown={visible}
        handleClose={() => setVisible(false)}
        toggleModal={() => setVisible(!visible)}
      >
        <div className={cx("cashback-note")}>
          <div>
            <h2>Hoàn tiền khi lắp đặt tại Uhomes</h2>
          </div>
          <p>
            Số tiền hoàn lại sẽ phụ thuộc vào gói lắp đặt, nhà mạng và hình thức
            đăng ký đóng tiền.
          </p>
          <p>
            Ví dụ: Lắp gói Combo Meta có giá 390.000đ/tháng đóng trước năm thì
            số tiền hoàn là 500.000đ
          </p>
          <p>
            Số tiền hoàn lại sẽ được báo chính xác khi Uhomes gọi điện tư vấn.
          </p>
        </div>
      </Modal>
      <div className={cx("bottom-row")}>
        <h3 className={cx("bottom-title")}>Giá ước tính</h3>
        <span className={cx("bottom-number")}>1.500.000đ</span>
      </div>
      <div className={cx("inner")}>
        <Button
          disabled={!internet.package ? true : false}
          primary
          onClick={() => navigate("/phone")}
        >
          Đăng ký/ Thay thế
        </Button>
      </div>
    </div>
  );
};
export default SeventhStep;
