import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Header from "~/layouts/components/Header";
import styles from "./SidebarLayout.module.scss";
import Sidebar from "../components/Sidebar";

const cx = classNames.bind(styles);

function SidebarLayout({ children }) {
  return (
    <div className={cx("wrapper")}>
      <Header />
      <div className={cx("container")}>
        <div className={cx("sidebar-container")}>
          <Sidebar />
        </div>
        <div className={cx("content")}>{children}</div>
      </div>
    </div>
  );
}

SidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarLayout;
