import classNames from "classnames/bind";
import AllText from "~/components/AllText";
import CardTypeThree from "~/components/Cards/CardTypeThree";
import styles from "./CardsThreeOp.module.scss";

const cx = classNames.bind(styles);

function CardsThreeOp({ item, bottom }) {
  return (
    <div className={cx("container")}>
      <div className={cx("mainSection")}>
        <div className={cx("left-mainSection")}>
          <AllText item={item} bottom={bottom} />
        </div>
        <div className={cx("right-mainSection")}>
          <CardTypeThree item={item} />
        </div>
      </div>
    </div>
  );
}

export default CardsThreeOp;
