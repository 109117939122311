import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "~/config/firebase";
import Item from "./Item";

function ListInternet() {
  const internet = useSelector((state) => state.internet);

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const getInternetPackages = async () => {
      const querySnapshot = await getDocs(collection(db, "internetPackages"));

      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setPackages(data);
    };

    getInternetPackages();
  }, []);

  console.log(internet);

  return (
    <div>
      {packages.map((item) => (
        <Item key={item.id} item={item} />
      ))}
    </div>
  );
}

export default ListInternet;
