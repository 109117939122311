import classNames from "classnames/bind";
import CardTypeManager from "~/components/Cards/CardTypeManager";
import CardTypeOne from "~/components/Cards/CardTypeOne";
import SideText from "../SideText";
import styles from "./CardsManager.module.scss";

const cx = classNames.bind(styles);

function CardsManager({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("mainSection")}>
        <div className={cx("left-mainSection")}>
          <SideText item={item} color="white" />
        </div>
        <div className={cx("right-mainSection")}>
          <CardTypeManager item={item} />
        </div>
      </div>
    </div>
  );
}

export default CardsManager;
