import { useState } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import SeventhStep from "./SeventhStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SmallLayout from "~/components/SmallLayout";
const cx = classNames.bind(styles);

function MovingSchedule() {
  const [info, setInfo] = useState({
    type: "",
    date: "",
    time: "",
    start: "",
    finish: "",
    note: "",
  });
  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <div>MovingSchedule</div>
          <FourthStep info={info} setInfo={setInfo} />
          <FifthStep info={info} setInfo={setInfo} />
          <SeventhStep />
        </div>
      </div>{" "}
    </SmallLayout>
  );
}

export default MovingSchedule;
