import React, { useState } from "react";
import styles from "./Login.module.scss";
import classNames from "classnames/bind";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import { useDispatch } from "react-redux";
import { login, userAuthStateListener } from "../../../redux/actions/auth"; // Đảm bảo rằng bạn đã import đúng file hành động

const cx = classNames.bind(styles);

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await dispatch(login(email, password));
      navigate("/");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className={cx("main")}>
      <h2 className={cx("heading")}>Đăng nhập</h2>

      <div className={cx("content")}>
        <div className={cx("title-container")}>
          <span>Email</span>
          <Link to="/phoneNumber">
            <span className={cx("link")}>Đăng nhập với số điện thoại</span>
          </Link>
        </div>
        <ul>
          <li>
            <input
              type="email"
              name="usr_email"
              id="usr_email"
              placeholder="Địa chỉ email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </li>
          <li>
            <input
              type="password"
              name="usr_password"
              id="usr_password"
              placeholder="Mật khẩu"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </li>
          <li style={{ textAlign: "right" }}>
            <Link className={cx("link")} to="/forgetPassword">
              Quên mật khẩu{" "}
            </Link>
          </li>
          {error && (
            <li>
              <p className={cx("error-message")}>{error}</p>
            </li>
          )}
          <li>
            <Button primary onClick={handleLogin}>
              Đăng nhập
            </Button>
          </li>
        </ul>
      </div>
      <div className={cx("bottom")}>
        <p className="tc mt3">
          Chưa có tài khoản?{" "}
          <Link className={cx("link")} to="/signup">
            Đăng ký
          </Link>
          .
        </p>
      </div>
    </div>
  );
}

export default Login;
