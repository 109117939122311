import {
  RESET_AIRCON,
  ADD_AIRCON_ITEM,
  MINUS_AIRCON_ITEM,
  ADD_AIRCON_QUANTITY_ITEM,
  MINUS_AIRCON_QUANTITY_ITEM,
  ADD_NOTE_ITEM_AIRCON,
  ADD_TOGGLE_AIRCON,
  AIRCON_DATE,
  AIRCON_TIME,
  AIRCON_TOTAL,
  AIRCON_NOTEPROS,
  AIRCON_CONTACT,
} from "../constants";

const initialState = {
  items: [],
  date: "",
  time: "",
  notePros: "",
  total: 0,
  discount: 0,
  contact: "",
};

export const aircon = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AIRCON_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case MINUS_AIRCON_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case ADD_AIRCON_QUANTITY_ITEM:
      const itemAdded = state.items.find((item) => item.id === action.payload);
      const newItemAdded = {
        ...itemAdded,
        quantity: itemAdded.quantity + 1,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload),
          newItemAdded,
        ],
      };

    case MINUS_AIRCON_QUANTITY_ITEM:
      const item = state.items.find((item) => item.id === action.payload);
      const newItem = {
        ...item,
        quantity: item.quantity - 1,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload),
          newItem,
        ],
      };

    case ADD_NOTE_ITEM_AIRCON:
      const itemNoteAdded = state.items.find(
        (item) => item.id === action.payload.id
      );
      const newNoteItemAdded = {
        ...itemNoteAdded,
        note: action.payload.note,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload.id),
          newNoteItemAdded,
        ],
      };

    case ADD_TOGGLE_AIRCON:
      const itemToggleAdded = state.items.find(
        (item) => item.id === action.payload.id
      );
      const newToggleItemAdded = {
        ...itemToggleAdded,
        gas: action.payload.gas,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload.id),
          newToggleItemAdded,
        ],
      };
    case AIRCON_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case AIRCON_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case AIRCON_TOTAL:
      return {
        ...state,
        total: action.payload,
      };

    case AIRCON_NOTEPROS:
      return {
        ...state,
        notePros: action.payload,
      };
    case AIRCON_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case RESET_AIRCON:
      return initialState;
    default:
      return state;
  }
};
