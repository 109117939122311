import classNames from "classnames/bind";
import images from "~/assets/images";
import styles from "./SideText.module.scss";

const cx = classNames.bind(styles);

function SideText({ item, bottom, color, app }) {
  return (
    <div style={color && { color }}>
      <h2 className={cx("pre")}>{item.pre}</h2>
      <h2 className={cx("title")} style={color && { color }}>
        {item.title}
      </h2>
      <p style={color && { color }} className={cx("subTitle")}>
        {item.text}
      </p>

      {bottom && bottom()}

      {app && (
        <div className={cx("icons")}>
          <a href="https://apps.apple.com/vn/app/uhomes/id6445883354?l=vi">
            <div className={cx("icon-appstore")}>
              <img alt="icon" src={images.appstore} />
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.uhomes.dichvuchocudan&hl=en"
          >
            <div className={cx("icon-appstore")}>
              <img alt="icon" src={images.playstore} />
            </div>
          </a>
        </div>
      )}
    </div>
  );
}

export default SideText;
