export const USER_STATE_CHANGE = "USER_STATE_CHANGE";

export const MODAL_OPEN_SEARCH_SECTION = "MODAL_OPEN_SEARCH_SECTION";
export const CLEAR_MODAL = "CLEAR_MODAL";
//current location
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const SET_NUMBER_OF_NOTIFICATIONS = "SET_NUMBER_OF_NOTIFICATIONS";

//moving
export const MOVING_START = "MOVING_START";
export const MOVING_START_FLOOR_NO = "MOVING_START_FLOOR_NO";
export const MOVING_START_HAS_ELEVATOR = "MOVING_START_HAS_ELEVATOR";
export const MOVING_FINISH = "MOVING_FINISH";
export const MOVING_FINISH_FLOOR_NO = "MOVING_FINISH_FLOOR_NO";
export const MOVING_FINISH_HAS_ELEVATOR = "MOVING_FINISH_HAS_ELEVATOR";
export const MOVING_TYPE = "SET_MOVING_TYPE";
export const MOVING_DISCOUNT = "MOVING_DISCOUNT";

export const MOVING_DATE = "SET_MOVING_DATE";
export const MOVING_TIME = "SET_MOVING_TIME";
export const MOVING_TOTAL = "MOVING_TOTAL";
export const MOVING_CONTACT = "MOVING_CONTACT";
export const MOVING_NOTE_DRIVER = "MOVING_NOTE_DRIVER";
export const MOVING_NOTE_STUFF = "MOVING_NOTE_STUFF";

export const START_MODAL = "START_MODAL";
export const START_LAT = "START_LAT";
export const START_LNG = "START_LNG";
export const FINISH_MODAL = "FINISH_MODAL";

export const FINISH_LAT = "FINISH_LAT";
export const FINISH_LNG = "FINISH_LNG";

export const RESET_MOVING = "RESET_MOVING";

//modals

export const SET_SUCCESS_MODAL = "SET_SUCCESS_MODAL";
export const SET_PRICE_DETAILS_MODAL = "SET_PRICE_DETAILS_MODAL";
export const SET_NOTE_DRIVER_MODAL = "SET_NOTE_DRIVER_MODAL";
export const SET_PHONE_NUMBER_MODAL = "SET_PHONE_NUMBER_MODAL";

//internet

export const INTERNET_PACKAGE = "INTERNET_PACKAGE";
export const INTERNET_PAYMENT_TYPE = "INTERNET_PAYMENT_TYPE";
export const INTERNET_DISCOUNT_TYPE = "INTERNET_DISCOUNT_TYPE";

export const INTERNET_DATE = "SET_INTERNET_DATE";
export const INTERNET_TIME = "SET_INTERNET_TIME";
export const RESET_INTERNET = "RESET_INTERNET";
export const INTERNET_DEVICES = "INTERNET_DEVICES";
export const INTERNET_TYPE = "INTERNET_TYPE";

export const INTERNET_NOTE_PROS = "INTERNET_NOTE_PROS";
export const INTERNET_LOCATION = "INTERNET_LOCATION";
export const INTERNET_CONTACT = "INTERNET_CONTACT";
export const INTERNET_TOTAL = "INTERNET_TOTAL";
export const SET_INTERNET_PURPOSE = "SET_INTERNET_PURPOSE";
export const MINUS_INTERNET_PURPOSE = "MINUS_INTERNET_PURPOSE";
export const SET_TV_PURPOSE = "SET_TV_PURPOSE";
export const MINUS_TV_PURPOSE = "MINUS_TV_PURPOSE";

//camera
export const CAMERA_WIRE = "CAMERA_WIRE";
export const CAMERA_TYPE = "CAMERA_TYPE";
export const CAMERA_NIGHT = "CAMERA_NIGHT";
export const SET_CAMERA_PROVIDERS = "SET_CAMERA_PROVIDERS";
export const MINUS_CAMERA_PROVIDERS = "MINUS_CAMERA_PROVIDERS";
export const CAMERA_CONTACT = "CAMERA_CONTACT";
export const CAMERA_DISCOUNT_TYPE = "CAMERA_DISCOUNT_TYPE";
export const CAMERA_DATE = "CAMERA_DATE";
export const CAMERA_TIME = "CAMERA_TIME";
export const CAMERA_NOTE_PROS = "CAMERA_NOTE_PROS";
export const CAMERA_TOTAL = "CAMERA_TOTAL";
export const MINUS_CAMERA_ITEM = "MINUS_CAMERA_ITEM";
export const ADD_CAMERA_ITEM = "ADD_CAMERA_ITEM";
export const ADD_CAMERA_QUANTITY_ITEM = "ADD_CAMERA_QUANTITY_ITEM";
export const MINUS_CAMERA_QUANTITY_ITEM = "MINUS_CAMERA_QUANTITY_ITEM";
export const RESET_CAMERA = "RESET_CAMERA";

//cleanOther
export const RESET_CLEANOTHER = "RESET_CLEANOTHER";
export const ADD_CLEANOTHER_ITEM = "ADD_CLEANOTHER_ITEM";
export const MINUS_CLEANOTHER_ITEM = "MINUS_CLEANOTHER_ITEM";
export const ADD_CLEANOTHER_QUANTITY_ITEM = "ADD_CLEANOTHER_QUANTITY_ITEM";
export const MINUS_CLEANOTHER_QUANTITY_ITEM = "MINUS_CLEANOTHER_QUANTITY_ITEM";
export const ADD_NOTE_ITEM_CLEANOTHER = "ADD_NOTE_ITEM_CLEANOTHER";
export const ADD_TOGGLE_CLEANOTHER = "ADD_TOGGLE_CLEANOTHER";
export const CLEANOTHER_NOTEPROS = "CLEANOTHER_NOTEPROS";
export const CLEANOTHER_CONTACT = "CLEANOTHER_CONTACT";
export const CLEANOTHER_TOTAL = "CLEANOTHER_TOTAL";
export const CLEANOTHER_DATE = "CLEANOTHER_DATE";
export const CLEANOTHER_TIME = "CLEANOTHER_TIME";
//aircon
export const RESET_AIRCON = "RESET_AIRCON";
export const ADD_AIRCON_ITEM = "ADD_AIRCON_ITEM";
export const MINUS_AIRCON_ITEM = "MINUS_AIRCON_ITEM";
export const ADD_AIRCON_QUANTITY_ITEM = "ADD_AIRCON_QUANTITY_ITEM";
export const MINUS_AIRCON_QUANTITY_ITEM = "MINUS_AIRCON_QUANTITY_ITEM";
export const ADD_NOTE_ITEM_AIRCON = "ADD_NOTE_ITEM_AIRCON";
export const ADD_TOGGLE_AIRCON = "ADD_TOGGLE_AIRCON";
export const AIRCON_DATE = "AIRCON_DATE";
export const AIRCON_TIME = "AIRCON_TIME";
export const AIRCON_TOTAL = "AIRCON_TOTAL";
export const AIRCON_NOTEPROS = "AIRCON_NOTEPROS";
export const AIRCON_CONTACT = "AIRCON_CONTACT";

//cleaning
export const CLEANING_PACKAGE = "CLEANING_PACKAGE";
export const CLEANING_EQUIPMENT = "CLEANING_EQUIPMENT";

export const CLEANING_DISCOUNT_TYPE = "CLEANING_DISCOUNT_TYPE";

export const CLEANING_DATE = "CLEANING_DATE";
export const CLEANING_TIME = "CLEANING_TIME";
export const CLEANING_NOTE_PROS = "CLEANING_NOTE_PROS";
export const CLEANING_CONTACT = "CLEANING_CONTACT";

export const RESET_CLEANING = "RESET_CLEANING";

//Painting
export const PAINTING_DATE = "PAINTING_DATE";
export const PAINTING_TIME = "PAINTING_TIME";
export const PAINTING_NOTEPROS = "PAINTING_NOTEPROS";
export const PAINTING_CONTACT = "PAINTING_CONTACT";
export const RESET_PAINTING = "RESET_PAINTING";

//Sanitizationexport
export const SANITIZATION_SIZE = "SANITIZATION_SIZE";
export const SANITIZATION_PLACE = "SANITIZATION_PLACE";
export const SANITIZATION_DATE = "SANITIZATION_DATE";
export const SANITIZATION_TIME = "SANITIZATION_TIME";
export const SANITIZATION_NOTEPROS = "SANITIZATION_NOTEPROS";
export const SANITIZATION_CONTACT = "SANITIZATION_CONTACT";
export const RESET_SANITIZATION = "RESET_SANITIZATION";

export const SET_REFERRAL_CODE = "SET_REFERRAL_CODE";
