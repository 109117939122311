import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

const auth = getAuth();

export const setUpRecaptha = (number) => {
  return new Promise((resolve, reject) => {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });

    signInWithPhoneNumber(auth, number, recaptchaVerifier)
      .then((confirmationResult) => {
        resolve(confirmationResult);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
