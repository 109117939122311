import classNames from "classnames/bind";
import styles from "./Sanitization.module.scss";
import Columns from "~/components/Columns";
import Sliders from "~/components/Sliders";
import { ListIcon, QualityListing, RealTimeIcon } from "~/components/Icons";
import CardsOtherSan from "~/components/TextAndImage/CardsOtherSan";
import Three from "~/components/TextAndImage/Three";
import ThreeOp from "~/components/TextAndImage/ThreeOp";
import { useNavigate } from "react-router-dom";
import Button from "~/components/Button/Button";

const cx = classNames.bind(styles);

function Sanitization() {
  const navigate = useNavigate();

  const handleAction = () => navigate("/sanitization/steps");
  const Bottom = () => (
    <Button onClick={handleAction} primary>
      Nhận báo giá miễn phí
    </Button>
  );
  return (
    <div className={cx("main")}>
      <CardsOtherSan item={itemTextAndImage} bottom={Bottom} />
      <Columns item={itemColumns1} bg="#e0ecfe" />
      <Three item={itemPartner} />
      <ThreeOp item={itemPartner2} />

      <Columns item={itemColumn2} />
      <Sliders item={itemSliders} />
    </div>
  );
}

export default Sanitization;

const itemTextAndImage = {
  title: "Sanitization đi thông minh nhất mà bạn từng thực hiện",
  text: "Báo giá tốt nhất dịch vụ chuyển nhà được Uhomes cung cấp. Chuyển nhà nhỏ, chuyển nhà lớn, đường dài, đường ngắn - chúng tôi đã phục vụ bạn.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/homepage/homepage-hero-people.png",
};

const itemColumns1 = {
  title: "Tăng sức mạnh cho cho việc chuyển nhà của bạn, lớn và nhỏ",
  text: "Từ dịch vụ cho thuê xe tải tự chuyển đến đóng gói và chuyển nhà trọn gói, việc tìm cho bạn loại trợ giúp phù hợp là điều chúng tôi làm tốt nhất.",
  intros: [
    {
      id: 1,
      title: "Tìm công ty chuyển nhà trọn gói hoàn hảo",
      subtile1:
        "We help hundreds of thousands of people find their perfect internet and TV plans each year.",
      subtile2:
        "From booking movers to forwarding your mail: stay on top of everything you .",
      icon: <QualityListing />,
    },
    {
      id: 2,
      title: "Hire moving labor for the heaviest items",
      subtile1:
        "Lắp đặt Internet & TV, Chuyển nhà, Dọn dẹp nhà, Sơn tường chỉ với vài click.",
      subtile2:
        "Lắp đặt Internet & TV, Chuyển nhà, Dọn dẹp nhà, Sơn tường chỉ với vài click.",
      icon: <ListIcon />,
    },
    {
      id: 3,
      title: "Rent a truck",
      subtile1:
        "Danh sách tin đăng được hàng ngàn chủ nhà và môi giới cho thuê đăng lên mỗi giờ.",
      subtile2:
        "Danh sách tin đăng được hàng ngàn chủ nhà và môi giới cho thuê đăng lên mỗi giờ.",
      icon: <RealTimeIcon />,
    },
  ],
};

const itemColumn2 = {
  title: "Discover more Updater services",
  text: "Save time, money, and headaches during your next move with Updater's digital moving services.",
  intros: [
    {
      id: 1,
      title: "Moving services",
      subtile1:
        "Move your whole home or just your heaviest items, and schedule it on the same-day or weeks in advance.",
      subtile2:
        "From booking movers to forwarding your mail: stay on top of everything you .",
      icon: <QualityListing />,
    },
    {
      id: 2,
      title: "Insurance",
      subtile1:
        "Transfer your existing policy or find a new one that's perfectly tailored to your unique needs. Let's get started.",
      subtile2:
        "Lắp đặt Internet & TV, Chuyển nhà, Dọn dẹp nhà, Sơn tường chỉ với vài click.",
      icon: <ListIcon />,
    },
    {
      id: 3,
      title: "Cập nhật liên tục",
      subtile1:
        "Danh sách tin đăng được hàng ngàn chủ nhà và môi giới cho thuê đăng lên mỗi giờ.",
      subtile2:
        "Danh sách tin đăng được hàng ngàn chủ nhà và môi giới cho thuê đăng lên mỗi giờ.",
      icon: <RealTimeIcon />,
    },
  ],
};

const itemPartner = {
  pre: "DÀNH CHO ĐỐI TÁC",
  title: "Cung cấp cho khách hàng của bạn một trải nghiệm chuyển nhà liền mạch",
  text: "Tự động hóa quy trình chuyển đến cho cư dân đồng thời gia tăng độ hài lòng đối với chất lượng dịch vụ môi giới của bạn. Uhomes mang đến các giải pháp thuận tiện và tiết kiệm thời gian được tùy chỉnh cho người mua/thuê cũng như người bán/cho thuê.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto,w_750/v1651499860/woman%20with%20dog%20looking%20at%20phone%20apartment.jpg",
};

const itemPartner2 = {
  pre: "DÀNH CHO ĐỐI TÁC",
  title: "Cung cấp cho khách hàng của bạn một trải nghiệm chuyển nhà liền mạch",
  text: "Tự động hóa quy trình chuyển đến cho cư dân đồng thời gia tăng độ hài lòng đối với chất lượng dịch vụ môi giới của bạn. Uhomes mang đến các giải pháp thuận tiện và tiết kiệm thời gian được tùy chỉnh cho người mua/thuê cũng như người bán/cho thuê.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto,w_750/v1651501816/woman%20feet%20sticking%20out%20of%20car.png",
};

const itemSliders = {
  title: "Internet and TV tips",
  text: "Chuyển đổi nhà cung cấp và không biết bắt đầu từ đâu? Chúng tôi có thể giúp.",
  list: [
    {
      id: 1,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617737158/shutterstock_720994654/shutterstock_720994654.jpg?_i=AA",
      title: "Create the Ultimate Home Office Setup in 5 Steps",
      description: "ưtgeargdsfgdsfg",
    },
    {
      id: 2,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/w_1024,h_683/f_auto,q_auto/v1603519436/calculator-desk_how-to-save-on-cable-internet-and-phone/calculator-desk_how-to-save-on-cable-internet-and-phone.jpg?_i=AA",
      title: "How to Save on Internet, Cable, and Phone",
      description:
        "Moving is expensive and knowing how to save on your internet, cable, and phone bills can really help balance out that spending. Curious about the best ways to save? Turns out, it’s not such a challenge once you know how. Here are 11 of our tried-and-true techniques for how you can save on internet, cable, and phone while still getting the most out of your monthly payments.",
    },
    {
      id: 3,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1619369585/family-sitting-on-sofa-i-1/family-sitting-on-sofa-i-1.jpg?_i=AA",
      title: "Moving While Expecting Kids? Be Sure to Set up TV and Internet",
      description:
        "Of all the things you deal with as a new parent during a household move, your belongings may be the most obvious. But what about services? When it comes to TV and internet, it pays to plan ahead and make sure you have service for every step of your journey. Here’s how to ensure you’re prepared to tackle parenthood, especially in your new home.",
    },
  ],
};
