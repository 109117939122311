import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import Switch from "./Switch";

import classNames from "classnames/bind";
import styles from "./AirconItem.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  addAirconItem,
  addAirconQuantityItem,
  addNoteItemAircon,
  minusAirconItem,
  minusAirconQuantityItem,
} from "~/redux/actions/aircon";
const cx = classNames.bind(styles);

const AirconItem = ({ item, title }) => {
  const aircon = useSelector((state) => state.aircon);
  const dispatch = useDispatch();
  const oldItem = aircon.items.find((i) => i.id === item?.id);

  const [isChecked, setIsChecked] = useState(oldItem ? true : false);
  const [note, setNote] = useState(oldItem ? oldItem?.note : "");
  const [count, setCount] = useState(oldItem ? oldItem?.quantity : 1);

  const handleAddItem = (item) => {
    dispatch(addAirconItem(item));
  };

  const handleMinusItem = (id) => {
    dispatch(minusAirconItem(id));
  };

  const handleAddQuantityItem = (item) => {
    dispatch(addAirconQuantityItem(item));
  };

  const handleMinusQuantityItem = (id) => {
    dispatch(minusAirconQuantityItem(id));
  };

  function onCheckmarkPress() {
    if (isChecked) {
      handleMinusItem(item.id);
    } else {
      handleAddItem({
        id: item.id,
        itemName: title,
        type: item.type,
        service: item.service,
        price: item.price,
        quantity: count,
        note,
      });
    }
    setIsChecked(!isChecked);
  }

  const handleMinus = () => {
    if (count > 1) {
      setCount(count - 1);
      handleMinusQuantityItem(item.id);
    } else {
      handleMinusItem(item.id);
      setIsChecked(false);
    }
  };

  const handlePlus = () => {
    setCount(count + 1);
    handleAddQuantityItem(item.id);
  };

  useEffect(() => {
    item?.id && note && dispatch(addNoteItemAircon({ id: item.id, note }));
  }, [note, item, dispatch]);

  return (
    <div className={cx("container")}>
      <div onClick={onCheckmarkPress}>
        <div className={cx("checkbox-item")}>
          <input
            className={cx("myinput")}
            type="checkbox"
            checked={isChecked}
            onChange={onCheckmarkPress}
          />
          <span style={{ flex: 1, textAlign: "left" }}>{title}</span>

          <div
            className={cx("mydisplay")}
            style={{
              backgroundColor: isChecked ? "#ff7800" : "white",
              border: `2px solid ${isChecked ? "#ff7800" : "darkgray"}`,
            }}
          >
            {isChecked && (
              <div className="tick">
                <FontAwesomeIcon icon={faCheck} color="white" />
              </div>
            )}
          </div>
        </div>
      </div>
      {isChecked && (
        <div className={cx("expand-container")}>
          <span>Số lượng</span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 8,
            }}
          >
            <div className={cx("count-button")} onClick={handleMinus}>
              <FontAwesomeIcon icon={faMinus} color="white" />
            </div>
            <div>{count}</div>
            <div className={cx("count-button")} onClick={handlePlus}>
              <FontAwesomeIcon icon={faPlus} color="white" />
            </div>
          </div>
          <div className={cx("gas-container")}>
            <span className={cx("gas-title")}>BƠM GAS</span>
            <Switch isChecked={true} />
          </div>
        </div>
      )}
    </div>
  );
};

export default AirconItem;
