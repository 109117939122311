// Import các thư viện cần thiết
import React from "react";
import styles from "./Privacy.module.scss"; // Import styles từ file Privacy.module.scss
import classNames from "classnames/bind"; // Import classNames/bind để quản lý lớp CSS

const cx = classNames.bind(styles); // Sử dụng classNames/bind với styles từ Privacy.module.scss

// Component PrivacyScreen
const PrivacyScreen = () => {
  return (
    <div className={cx("privacy-container")}>
      <h2 className={cx("privacy-title")}>Chính sách bảo mật của Uhomes</h2>
      <p className={cx("privacy-text")}>
        Chính sách bảo mật này mô tả cách Uhomes thu thập, sử dụng và bảo vệ
        thông tin cá nhân của người dùng khi họ truy cập vào website và ứng dụng
        của chúng tôi. Uhomes cam kết bảo vệ thông tin cá nhân của bạn và tuân
        thủ nghiêm ngặt các quy định về bảo mật.
      </p>
      <h3 className={cx("privacy-subtitle")}>1. Tổng quan</h3>
      <p className={cx("privacy-text")}>
        Chúng tôi có thể thu thập các thông tin cá nhân như tên, địa chỉ email,
        số điện thoại, địa chỉ nhà và thông tin thanh toán khi người dùng đăng
        ký và sử dụng dịch vụ của chúng tôi.
      </p>
      <h3 className={cx("privacy-subtitle")}>2. Thu thập thông tin cá nhân</h3>
      <p className={cx("privacy-text")}>
        Thông tin cá nhân mà chúng tôi thu thập từ bạn có thể được sử dụng để
        cung cấp và quản lý dịch vụ Uhomes, cải thiện trải nghiệm người dùng và
        gửi thông tin marketing.
      </p>
      <h3 className={cx("privacy-subtitle")}>3. Sử dụng thông tin cá nhân</h3>
      <p className={cx("privacy-text")}>
        Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các đối tác dịch
        vụ để hỗ trợ hoạt động kinh doanh hoặc theo yêu cầu pháp luật.
      </p>
      <h3 className={cx("privacy-subtitle")}>4. Chia sẻ thông tin cá nhân</h3>
      <p className={cx("privacy-text")}>
        Uhomes cam kết bảo vệ thông tin cá nhân của bạn và thực hiện các biện
        pháp bảo mật để ngăn chặn truy cập trái phép, sử dụng hoặc tiết lộ thông
        tin cá nhân.
      </p>
      <h3 className={cx("privacy-subtitle")}>5. Bảo mật thông tin cá nhân</h3>
      <p className={cx("privacy-text")}>
        Bạn có quyền yêu cầu truy cập, sửa đổi hoặc xóa thông tin cá nhân của
        mình và từ chối nhận thông tin marketing từ Uhomes.
      </p>
      <h3 className={cx("privacy-subtitle")}>
        6. Quyền lựa chọn và điều chỉnh
      </h3>
      <p className={cx("privacy-text")}>
        Chúng tôi có thể cập nhật chính sách bảo mật này để phản ánh các thay
        đổi trong các yêu cầu pháp lý hoặc hoạt động của chúng tôi. Bất kỳ thay
        đổi nào sẽ được thông báo trước qua email hoặc trên website của chúng
        tôi.
      </p>
      <h3 className={cx("privacy-subtitle")}>7. Thay đổi chính sách</h3>
      <p className={cx("privacy-text")}>
        Nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào về chính sách bảo mật của
        chúng tôi, xin vui lòng liên hệ với chúng tôi qua email:
        privacy@uhomes.com.
      </p>
    </div>
  );
};

export default PrivacyScreen;
