import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";
import Search from "~/components/LiveSearch/Search";
import { useEffect, useState, useRef } from "react";

const cx = classNames.bind(styles);

const FirstStep = ({ info, setInfo }) => {
  const [value, setValue] = useState(info.address);
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    setInfo((prev) => ({ ...prev, address: value }));
  }, [value, setInfo]);
  return (
    <div className={cx("section")} ref={ref}>
      <div className={cx("left")}>
        <h2 className={cx("title")}>Xin vui lòng cho biết địa chỉ sơn nhà.</h2>
      </div>
      <div className={cx("right")}>
        <Search value={value} setValue={setValue} />
      </div>
    </div>
  );
};
export default FirstStep;
