import config from "~/config";

// Layouts
import HeaderOnly from "~/layouts/HeaderOnly";
import SidebarLayout from "../layouts/SidebarLayout";
import DefaultLayout from "~/layouts/DefaultLayout";

// Pages
import Home from "~/pages/Home";

import Internet from "~/pages/Services/Internet";

import Moving from "~/pages/Services/Moving";

import Blog from "~/pages/Blog";

import Solutions from "~/pages/Partners/Solutions";
import Agency from "~/pages/Partners/Agency";
import Providers from "~/pages/Partners/RentalManager";
import Cleaning from "~/pages/Services/Cleaning";
import Aircon from "~/pages/Services/Aircon";
import Sanitization from "~/pages/Services/Sanitization";
import Painting from "~/pages/Services/Painting";
import MovingTips from "~/pages/Blog/MovingTips";
import News from "~/pages/Blog/News";
import MovingDetails from "~/pages/Blog/MovingTips/MovingDetails";
import NewsDetails from "~/pages/Blog/News/NewsDetails";
import InternetSteps from "~/pages/Services/Internet/InternetSteps";

import InternetPackages from "~/pages/Services/Internet/InternetPackages";

import MovingSteps from "~/pages/Services/Moving/MovingSteps";
import CleaningSteps from "~/pages/Services/Cleaning/CleaningSteps";
import SanitizationSteps from "~/pages/Services/Sanitization/SanitizationSteps";
import AirconSteps from "~/pages/Services/Aircon/AirconSteps";
import PaintingSteps from "~/pages/Services/Painting/PaintingSteps";
import SuccessPage from "~/components/SuccessPage";
import TabContentScreen from "~/pages/Services/Aircon/TabContentScreen";
import MovingSize from "~/pages/Services/Moving/MovingSize";
import MovingSchedule from "~/pages/Services/Moving/MovingSchedule";
import Jobs from "../pages/others/Jobs";

//Authentication
import Signup from "../pages/Authentication/Signup";
import Login from "../pages/Authentication/Login";
import PhoneNumber from "../pages/Authentication/PhoneNumber";
import ForgotPassword from "../pages/Authentication/ForgetPassword";
import Dashboard from "../pages/Profiles/Dashboard";
import Setting from "../pages/Profiles/Setting";
import Referrals from "../pages/Profiles/Referrals";
import Notifications from "../pages/Profiles/Notifications";
import Knowledge from "../pages/Profiles/Knowledge";
import Privacy from "../pages/Profiles/Privacy";
import ReferralPage from "../pages/Partners/ReferralPage";
import Admin from "../pages/Admin";
import Cms from "../pages/Cms";
import Supply from "../pages/Supply";

// Public routes
const publicRoutes = [
  { path: config.routes.home, component: Home, layout: DefaultLayout },

  { path: config.routes.internet, component: Internet, layout: DefaultLayout },
  {
    path: config.routes.internetSteps,
    component: InternetSteps,
    layout: null,
  },
  {
    path: config.routes.internetPackages,
    component: InternetPackages,
    layout: null,
  },

  { path: config.routes.aircon, component: Aircon, layout: DefaultLayout },
  {
    path: config.routes.airconSteps,
    component: AirconSteps,
    layout: HeaderOnly,
  },

  {
    path: config.routes.airconMaintain,
    component: TabContentScreen,
    layout: HeaderOnly,
  },

  {
    path: config.routes.moving,
    component: Moving,
    layout: DefaultLayout,
  },

  {
    path: config.routes.movingSteps,
    component: MovingSteps,
    layout: HeaderOnly,
  },
  {
    path: config.routes.movingSize,
    component: MovingSize,
    layout: HeaderOnly,
  },
  {
    path: config.routes.movingSchedule,
    component: MovingSchedule,
    layout: HeaderOnly,
  },
  {
    path: config.routes.cleaning,
    component: Cleaning,
    layout: DefaultLayout,
  },
  {
    path: config.routes.cleaningSteps,
    component: CleaningSteps,
    layout: HeaderOnly,
  },

  {
    path: config.routes.sanitization,
    component: Sanitization,
    layout: DefaultLayout,
  },
  {
    path: config.routes.sanitizationSteps,
    component: SanitizationSteps,
    layout: HeaderOnly,
  },

  {
    path: config.routes.painting,
    component: Painting,
    layout: DefaultLayout,
  },
  {
    path: config.routes.paintingSteps,
    component: PaintingSteps,
    layout: HeaderOnly,
  },

  {
    path: config.routes.movingTips,
    component: MovingTips,
    layout: HeaderOnly,
  },

  {
    path: config.routes.movingDetails,
    component: MovingDetails,
    layout: HeaderOnly,
  },
  {
    path: config.routes.news,
    component: News,
    layout: HeaderOnly,
  },

  {
    path: config.routes.success,
    component: SuccessPage,
    layout: null,
  },

  {
    path: config.routes.newsDetails,
    component: NewsDetails,
    layout: HeaderOnly,
  },
  {
    path: config.routes.blog,
    component: Blog,
    layout: HeaderOnly,
  },
  {
    path: config.routes.solutions,
    component: Solutions,
    layout: HeaderOnly,
  },
  {
    path: config.routes.blog,
    component: Blog,
    layout: HeaderOnly,
  },
  {
    path: config.routes.agency,
    component: Agency,
    layout: HeaderOnly,
  },
  {
    path: config.routes.providers,
    component: Providers,
    layout: HeaderOnly,
  },
  {
    path: config.routes.jobs,
    component: Jobs,
    layout: HeaderOnly,
  },
  {
    path: config.routes.signup,
    component: Signup,
    layout: HeaderOnly,
  },
  {
    path: config.routes.login,
    component: Login,
    layout: HeaderOnly,
  },
  {
    path: config.routes.forgetPassword,
    component: ForgotPassword,
    layout: HeaderOnly,
  },
  {
    path: config.routes.phoneNumber,
    component: PhoneNumber,
    layout: HeaderOnly,
  },

  {
    path: config.routes.notifications,
    component: Notifications,
    layout: SidebarLayout,
  },

  {
    path: config.routes.knowledge,
    component: Knowledge,
    layout: SidebarLayout,
  },

  {
    path: config.routes.privacy,
    component: Privacy,
    layout: SidebarLayout,
  },

  {
    path: config.routes.referralCode,
    component: ReferralPage,
    layout: HeaderOnly,
  },
];

const protectedRoutes = [
  {
    path: config.routes.dashboard,
    component: Dashboard,
    layout: SidebarLayout,
  },

  {
    path: config.routes.setting,
    component: Setting,
    layout: SidebarLayout,
  },
  {
    path: config.routes.referrals,
    component: Referrals,
    layout: SidebarLayout,
  },
  {
    path: config.routes.admin,
    component: Admin,
    layout: SidebarLayout,
  },
  {
    path: config.routes.cms,
    component: Cms,
    layout: SidebarLayout,
  },
  {
    path: config.routes.supply,
    component: Supply,
    layout: SidebarLayout,
  },
];

export { publicRoutes, protectedRoutes };
