import classNames from "classnames/bind";
import styles from "./Solutions.module.scss";
import Sliders from "~/components/Sliders";
import It from "~/components/TextAndImage/It";
import AllText from "~/components/AllText";
import FourPads from "~/components/Pads/FourPads";
import icons from "~/assets/icons";
import Button from "~/components/Button";
import images from "~/assets/images";
import Ti from "~/components/TextAndImage/Ti";
import ThreeOp from "~/components/TextAndImage/CardsThreeOp";

const cx = classNames.bind(styles);

function Solutions() {
  return (
    <div className={cx("main")}>
      <AllText item={itemSolutions} center />
      <FourPads title="Chuyên gia về công nghệ chuyển nhà" data={padsData} />
      <It item={itemPartner} />
      <Ti item={itemCustomer} />
      <It item={itemPartner2} />
      <ThreeOp item={itemCustomer2} />
      <Sliders item={itemSliders} />
    </div>
  );
}

export default Solutions;

const itemSolutions = {
  pre: "",
  title:
    "Sử dụng chuyển nhà như một đòn bẩy tăng trưởng cho công việc kinh doanh của bạn",
  text: "Chúng tôi xây dựng các sản phẩm và giải pháp công nghệ giúp di chuyển dễ dàng hơn từ mọi vị trí thuận lợi. Mỗi phút, 14 hộ gia đình sử dụng các sản phẩm của chúng tôi để chuyển nhà, giúp chúng tôi trở thành nền tảng hàng đầu trong công nghệ chuyển nhà. Tham gia với chúng tôi để tăng sức mạnh di chuyển không ma sát cho mọi người, ở mọi nơi.",
  action: <Button primary>Hợp tác với chúng tôi</Button>,
};

const itemCustomer = {
  pre: "ĐỐI TÁC ĐẠI LÝ MÔI GIỚI BẤT ĐỘNG SẢN",
  title: "Mở rộng mối quan hệ khách hàng vào ngôi nhà mới",
  text: "Cung cấp cho người mua và người bán của bạn các công cụ để chuyển đến và quản lý ngôi nhà mới của họ. Tham gia cùng hàng chục nghìn chuyên gia bất động sản tùy chỉnh Trình cập nhật để giữ liên lạc lâu dài sau khi di chuyển.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto,w_750/v1651508495/Couple_rolling_out_carpet_new_home_hvubec.png",
};

const itemCustomer2 = {
  pre: "NỀN TẢNG CỦA BÊN THỨ BA",
  title: "Nền tảng của bạn, API của chúng tôi",
  text: "Tận dụng bộ API của chúng tôi để đưa các sản phẩm và dịch vụ liên quan đến chuyển động trực tiếp vào nền tảng của riêng bạn. Chúng tôi có thể giúp bạn đặt hàng kỹ thuật số hoặc điện thoại cho internet, TV, bảo hiểm, v.v.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto,w_750/v1651509623/couple_settling_into_new_home_xk3nfl.png",
};

const itemPartner = {
  pre: "ĐỐI TÁC QUẢN LÝ BẤT ĐỘNG SẢN",
  title: "Di chuyển vào được thực hiện ngay",
  text: "Tự động hóa việc chuyển đến của cư dân trong tương lai, giúp cư dân và nhân viên đi đúng hướng. Tham gia hàng nghìn cộng đồng tận dụng Trình cập nhật để định cấu hình các yêu cầu chuyển đến, duy trì sự tập trung cao độ của các nhóm cho thuê và thúc đẩy doanh thu phụ trợ.",
  imageExtra: images.frontpageCleaning,
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/updater-dot-com/inverted-smiling-couple.png",
};

const itemPartner2 = {
  pre: "CÁC NHÀ CUNG CẤP DỊCH VỤ",
  title: "Tham gia với động lực vào đúng thời điểm",
  text: "Tận dụng Trình cập nhật để tiếp cận hàng triệu người di chuyển một cách thông minh tại tất cả các điểm trong vòng đời di chuyển. Chúng tôi cung cấp các giải pháp tích hợp, nhắm mục tiêu và kịp thời để cung cấp khách hàng chứ không phải khách hàng tiềm năng.",
  imageUrl:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto,r_90/v1651509414/man_smiling_in_front_of_laptop_odf3u3.png",
  imageExtra:
    "https://res.cloudinary.com/updater-marketing/image/upload/f_auto,q_auto/v1651513249/services-abstraction_x56wrf.svg",
};

export const itemSliders = {
  title: "Thông tin chi tiết hỗ trợ doanh nghiệp của bạn",
  text: "Khám phá những ý tưởng và công nghệ định hình ngành công nghiệp của chúng tôi.",

  list: [
    {
      id: 1,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617737158/shutterstock_720994654/shutterstock_720994654.jpg?_i=AA",
      title:
        "7 cách trải nghiệm Uhomes nâng cao sẽ giúp cuộc sống của cư dân nhiều gia đình và các nhóm tại chỗ dễ dàng hơn",
      description:
        "Khi ngày chuyển nhà cuối cùng cũng đến, bạn có thể thở phào nhẹ nhõm. Những ngày đóng gói và lên kế hoạch đã qua, và bây giờ tất cả những gì còn lại là ổn định cuộc sống tại ngôi nhà mới của bạn. Tuy nhiên, ngày chuyển nhà là một nỗ lực hợp tác — cho dù bạn đang làm việc với những người chuyển nhà chuyên nghiệp hay nhờ sự giúp đỡ của bạn bè, bạn nên cân nhắc xem các nghi thức chuyển nhà giúp quá trình này trở nên dễ dàng hơn như thế nào đối với mọi người tham gia vào ngày trọng đại.",
      introDetails:
        "Dưới đây là chín bước bạn có thể thực hiện trong quá trình chuyển nhà để khiến một ngày trôi qua nhanh chóng và giữ cho mọi người có tinh thần tốt.",
      details: [
        {
          id: 1,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Khi bạn thức dậy vào ngày chuyển nhà, đồ đạc của bạn đã được đóng gói, dán nhãn và sẵn sàng để chất lên xe tải. Bạn có thể cần đóng gói một số đồ vệ sinh cá nhân và khăn trải giường vào buổi sáng ngày chuyển đi, nhưng phần lớn việc đóng gói nên được hoàn thành trước. Điều này cho phép máy động lực của bạn đến và làm việc ngay lập tức.",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 2,
          title: "Đóng gói gọn gàng, dán nhãn rõ ràng",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 3,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999723/shutterstock_1170001924/shutterstock_1170001924.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 4,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 5,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 6,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 7,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl:
            "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617999373/shutterstock_1557782294/shutterstock_1557782294.jpg?_i=AA",
          style: { height: 300 },
        },
        {
          id: 8,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
        {
          id: 9,
          title: "Đóng gói mọi thứ và sẵn sàng lên đường",
          content: [
            "Trước khi máy di chuyển đến, hãy kiểm tra lần cuối để đảm bảo rằng tất cả đồ đạc của bạn được đóng hộp an toàn trong các thùng chứa dễ mang theo. Tránh trình bày những người di chuyển của bạn với một đống đồ đạc trong túi rác.",
            "Ngoài ra, bạn dán nhãn hộp càng rõ ràng thì càng tốt. Sử dụng bút đánh dấu đậm để viết vị trí của mỗi hộp, với phòng và ký hiệu phụ (ví dụ: đồ hộp, đồ chơi, sách) nếu cần. Để giúp mọi việc dễ dàng hơn trong ngôi nhà mới của bạn, đừng quên dán biển báo có thông tin đó để hộp được đặt đúng phòng (ví dụ: phòng ngủ chính, phòng ngủ 2, phòng tắm chính, phòng tắm 2, v.v.).",
            "Để giúp tránh mọi nhầm lẫn có thể xảy ra trong quá trình chuyển nhà, hãy đảm bảo rằng bạn hoặc người khác có danh sách hàng tồn kho — bao gồm các hộp và đồ nội thất — để đánh dấu khi các đồ đạc được chuyển ra khỏi nhà cũ và chuyển đến nhà mới của bạn. Bạn không muốn thấy mình băn khoăn và tự hỏi liệu mọi thứ đã thay đổi chưa.\nCuối cùng, hãy nhớ đánh dấu các thùng chứa các vật sắc nhọn hoặc dễ vỡ .",
          ],
          imageUrl: "",
          style: { height: 300 },
        },
      ],
    },
    {
      id: 2,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/w_1024,h_683/f_auto,q_auto/v1603519436/calculator-desk_how-to-save-on-cable-internet-and-phone/calculator-desk_how-to-save-on-cable-internet-and-phone.jpg?_i=AA",
      title: "How to Save on Internet, Cable, and Phone",
      description:
        "Moving is expensive and knowing how to save on your internet, cable, and phone bills can really help balance out that spending. Curious about the best ways to save? Turns out, it’s not such a challenge once you know how. Here are 11 of our tried-and-true techniques for how you can save on internet, cable, and phone while still getting the most out of your monthly payments.",
    },
    {
      id: 3,
      imageUrl:
        "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1619369585/family-sitting-on-sofa-i-1/family-sitting-on-sofa-i-1.jpg?_i=AA",
      title: "Moving While Expecting Kids? Be Sure to Set up TV and Internet",
      description:
        "Of all the things you deal with as a new parent during a household move, your belongings may be the most obvious. But what about services? When it comes to TV and internet, it pays to plan ahead and make sure you have service for every step of your journey. Here’s how to ensure you’re prepared to tackle parenthood, especially in your new home.",
    },
  ],
};

export const padsData = [
  {
    id: 1,
    name: "Thiết lập công nghệ hàng đầu trong ngành",
    icon: icons.internet,
    className: "item1",
  },
  {
    id: 2,
    name: "Tích hợp đầu cuối",
    icon: icons.moving,
    className: "item2",
  },
  {
    id: 3,
    name: "Mọi đối tác đã được phê duyệt",
    icon: icons.cleaning,
    className: "item3",
  },
  {
    id: 4,
    name: "Thu nhỏ để giành chiến thắng",
    icon: icons.aircon,
    className: "item4",
  },
];
