import classNames from "classnames/bind";
import styles from "./CardTypeAgency.module.scss";

const cx = classNames.bind(styles);

function CardTypeAgency({ item }) {
  return (
    <div className={cx("container")}>
      <div className={cx("inner")}>
        <div className="visual-abstraction-inner">
          <img
            alt=""
            className="visual-abstraction-image"
            src="https://updater.com/images/real-estate/box-message-card.svg"
          />
          <div className={cx("extra-image")}>
            <img
              alt=""
              src="https://updater.com/images/real-estate/utilities-abstraction.svg"
            />
          </div>
          <div
            className="visual-abstraction-abstraction-width"
            style={{ width: "100%" }}
          ></div>
        </div>
      </div>
      <div className={cx("space")}></div>
    </div>
  );
}

export default CardTypeAgency;
