import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Sidebar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../../redux/actions/auth";
import Button from "../../../components/Button";

const cx = classNames.bind(styles);

function Sidebar({
  toggleModal = () => {
    console.log("Màn hình destop");
  },
}) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleClick = (link) => {
    toggleModal(false);
    navigate(link);
  };

  return (
    <div className={cx("sidebar")}>
      <div className={cx("account-info")}>
        {currentUser?.isAdmin && (
          <>
            <div
              className={cx("balance")}
              style={{
                backgroundColor:
                  location.pathname === "/admin"
                    ? "rgba(0,0,0,0.1)"
                    : "transparent",
              }}
            >
              <div
                onClick={() => handleClick("/admin")}
                className={cx("sidebar-link")}
              >
                <span>Quản lý đơn hàng</span>
              </div>
              <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </div>

            <div
              className={cx("balance")}
              style={{
                backgroundColor:
                  location.pathname === "/cms"
                    ? "rgba(0,0,0,0.1)"
                    : "transparent",
              }}
            >
              <div
                onClick={() => handleClick("/cms")}
                className={cx("sidebar-link")}
              >
                <span>Quản lý khách hàng</span>
              </div>
              <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </div>

            <div
              className={cx("balance")}
              style={{
                backgroundColor:
                  location.pathname === "/supply"
                    ? "rgba(0,0,0,0.1)"
                    : "transparent",
              }}
            >
              <div
                onClick={() => handleClick("/supply")}
                className={cx("sidebar-link")}
              >
                <span>Quản lý nhà cung cấp</span>
              </div>
              <FontAwesomeIcon icon={faChevronRight} size="1x" />
            </div>
          </>
        )}

        <div
          className={cx("balance")}
          style={{
            backgroundColor:
              location.pathname === "/setting"
                ? "rgba(0,0,0,0.1)"
                : "transparent",
          }}
        >
          <div
            onClick={() => handleClick("/setting")}
            className={cx("sidebar-link")}
          >
            <span>Thông tin cá nhân</span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </div>

        <div
          className={cx("balance")}
          style={{
            backgroundColor:
              location.pathname === "/referrals"
                ? "rgba(0,0,0,0.1)"
                : "transparent",
          }}
        >
          <div
            onClick={() => handleClick("/referrals")}
            className={cx("sidebar-link")}
          >
            <span>Chương trình giới thiệu</span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </div>

        <div
          className={cx("balance")}
          style={{
            backgroundColor:
              location.pathname === "/notifications"
                ? "rgba(0,0,0,0.1)"
                : "transparent",
          }}
        >
          <div
            onClick={() => handleClick("/notifications")}
            className={cx("sidebar-link")}
          >
            <span>Thông báo</span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </div>

        <div
          className={cx("balance")}
          style={{
            backgroundColor:
              location.pathname === "/knowledge"
                ? "rgba(0,0,0,0.1)"
                : "transparent",
          }}
        >
          <div
            onClick={() => handleClick("/knowledge")}
            className={cx("sidebar-link")}
          >
            <span>Kiến thức</span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </div>

        <div
          className={cx("balance")}
          style={{
            backgroundColor:
              location.pathname === "/privacy"
                ? "rgba(0,0,0,0.1)"
                : "transparent",
          }}
        >
          <div
            onClick={() => handleClick("/privacy")}
            className={cx("sidebar-link")}
          >
            <span>Điều khoản & Bảo mật</span>
          </div>
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </div>
      </div>

      <Button primary onClick={handleLogout} className={cx("home-button")}>
        Đăng xuất
      </Button>
    </div>
  );
}

export default Sidebar;
