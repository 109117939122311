const icons = {
  aircon: require("~/assets/icons/aircon.svg").default,
  cleaning: require("~/assets/icons/cleaning1.svg").default,
  internet: require("~/assets/icons/internet.svg").default,
  moving: require("~/assets/icons/moving.svg").default,
  sanitization: require("~/assets/icons/sanitizationIcon.svg").default,
  painting: require("~/assets/icons/painting1.svg").default,
  camera: require("~/assets/icons/securitycamera.svg").default,
  sofa: require("~/assets/icons/sofa.svg").default,
};

export default icons;
