import {
  INTERNET_DATE,
  INTERNET_DISCOUNT_TYPE,
  INTERNET_NOTE_PROS,
  INTERNET_PACKAGE,
  INTERNET_PAYMENT_TYPE,
  INTERNET_TIME,
  RESET_INTERNET,
  INTERNET_LOCATION,
  INTERNET_CONTACT,
  INTERNET_TOTAL,
  SET_INTERNET_PURPOSE,
  MINUS_INTERNET_PURPOSE,
  INTERNET_TYPE,
  INTERNET_DEVICES,
  SET_TV_PURPOSE,
  MINUS_TV_PURPOSE,
} from "../constants";
export const internetPackage = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_PACKAGE });
};

export const internetLocation = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_LOCATION });
};

export const internetContact = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_CONTACT });
};

export const internetDevices = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_DEVICES });
};

export const internetDate = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_DATE });
};

export const internetTime = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_TIME });
};

export const internetPaymentType = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_PAYMENT_TYPE });
};

export const internetDiscount = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_DISCOUNT_TYPE });
};

export const internetType = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_TYPE });
};

export const internetNotePros = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_NOTE_PROS });
};

export const internetTotal = (data) => (dispatch) => {
  dispatch({ payload: data, type: INTERNET_TOTAL });
};

export const resetInternet = () => (dispatch) => {
  dispatch({ type: RESET_INTERNET });
};

export const setInternetPurpose = (data) => (dispatch) =>
  dispatch({ payload: data, type: SET_INTERNET_PURPOSE });

export const minusInternetPurpose = (data) => (dispatch) =>
  dispatch({ payload: data, type: MINUS_INTERNET_PURPOSE });

export const setTVPurpose = (data) => (dispatch) =>
  dispatch({ payload: data, type: SET_TV_PURPOSE });

export const minusTVPurpose = (data) => (dispatch) =>
  dispatch({ payload: data, type: MINUS_TV_PURPOSE });
