import React, { useState } from "react";
import styles from "./AvatarUpload.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const AvatarUpload = ({ photoUrl, setPhotoFile, setPhotoUrl }) => {
  const [preview, setPreview] = useState(photoUrl);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhotoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setPhotoUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={cx("avatarUpload")}>
      <div className={cx("avatarPreview")}>
        <img src={preview} alt="Avatar Preview" />
      </div>
      <input
        type="file"
        id="avatar"
        accept="image/*"
        onChange={handleFileChange}
        className={cx("fileInput")}
      />
      <label htmlFor="avatar" className={cx("uploadButton")}>
        Thay ảnh đại diện
      </label>
    </div>
  );
};

export default AvatarUpload;
