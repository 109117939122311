import React from "react";
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import AirconCeiling from "./AirconCeiling";
import AirconCassette from "./AirconCassette";
import AirconCeilingConcealed from "./AirconCeilingConcealed";
import AirconHangWall from "./AirconHangWall";
import AirconStand from "./AirconStand";
import classNames from "classnames/bind";
import styles from "./TabContentScreen.module.scss";
import SmallLayout from "~/components/SmallLayout";
import { useNavigate } from "react-router-dom";
import image from "~/assets/icons/back.svg";
import Button from "~/components/Button/Button";

const cx = classNames.bind(styles);

export default function TabContentScreen() {
  const [activeTab, setActiveTab] = React.useState(0);

  const navigate = useNavigate();
  // define an onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  const handleSave = () => {
    navigate("/aircon/steps");
  };

  return (
    <SmallLayout>
      <div className={cx("tabs-container")}>
        <div onClick={handleSave}>
          <img alt="back-button" src={image} className={cx("back-button")} />
        </div>
        <Tabs
          activeTab={activeTab}
          onTabClick={onTabClick}
          // hideNavBtnsOnMobile={false}
          mode="scrollSelectedToCenterFromView"
        >
          <Tab key={0}>Treo tường</Tab>
          <Tab key={1}>Tủ đứng</Tab>
          <Tab key={2}>Âm trần</Tab>
          <Tab key={3}>Áp trần</Tab>
          <Tab key={4}>Giấu trần</Tab>
        </Tabs>
        <AirconHangWall activeTab={activeTab} idx={0} key={1}>
          Page {1}
        </AirconHangWall>
        <AirconStand activeTab={activeTab} idx={1} key={2}>
          Page {2}
        </AirconStand>
        <AirconCassette activeTab={activeTab} idx={2} key={3}>
          Page {3}
        </AirconCassette>
        <AirconCeiling activeTab={activeTab} idx={3} key={4}>
          Page {4}
        </AirconCeiling>
        <AirconCeilingConcealed activeTab={activeTab} idx={4} key={5}>
          Page {5}
        </AirconCeilingConcealed>
        <Button primary onClick={handleSave}>
          Lưu
        </Button>
      </div>
    </SmallLayout>
  );
}
