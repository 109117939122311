import classNames from "classnames/bind";
import images from "~/assets/images";
import styles from "./AllText.module.scss";

import { TypeAnimation } from "react-type-animation";

const cx = classNames.bind(styles);

function AllText({ item, center, bottom }) {
  return (
    <div className={cx("container")}>
      <div
        className={cx("inner")}
        style={
          center && {
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            // marginBottom: 72,
            // paddingTop: 72,
          }
        }
      >
        <h2 className={cx("pre")}>{item.pre}</h2>

        {!bottom ? (
          <div className={cx("title")} style={{ display: "inline-block" }}>
            <span style={{ fontWeight: "400", display: "block" }}>
              Chưa bao giờ{" "}
            </span>
            <TypeAnimation
              sequence={[
                "chuyển nhà",
                1000,
                "lắp đặt Internet",
                1000,
                "dọn dẹp nhà",
                1000,
                "sơn nhà",
                1000,
                "khử trùng",
                1000,
              ]}
              repeat={Infinity}
              speed={1}
              deletionSpeed={1}
              style={{ color: "rgb(52,199,89)", fontWeight: "500" }}
            />
            <span style={{ fontWeight: "400", display: "block" }}>
              dễ như bây giờ
            </span>
          </div>
        ) : (
          <h2 className={cx("title")}>{item.title}</h2>
        )}

        <p className={cx("subTitle")}>{item.text}</p>
        {/* {item?.action && item?.action()} */}
        {bottom ? (
          bottom()
        ) : (
          <div
            style={
              center && {
                textAlign: "center",
              }
            }
          >
            <div className={cx("icons")}>
              <a href="https://apps.apple.com/vn/app/uhomes/id6445883354?l=vi">
                <div className={cx("icon-appstore")}>
                  <img alt="icon" src={images.appstore} />
                </div>
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.uhomes.dichvuchomoinha"
              >
                <div className={cx("icon-appstore")}>
                  <img alt="icon" src={images.playstore} />
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllText;
