import {
  CAMERA_WIRE,
  CAMERA_TYPE,
  CAMERA_NIGHT,
  SET_CAMERA_PROVIDERS,
  MINUS_CAMERA_PROVIDERS,
  CAMERA_CONTACT,
  CAMERA_DISCOUNT_TYPE,
  CAMERA_DATE,
  CAMERA_TIME,
  CAMERA_NOTE_PROS,
  CAMERA_TOTAL,
  RESET_CAMERA,
  MINUS_CAMERA_ITEM,
  ADD_CAMERA_ITEM,
  ADD_CAMERA_QUANTITY_ITEM,
  MINUS_CAMERA_QUANTITY_ITEM,
} from "../constants";

const initialState = {
  wire: "",
  providers: [],
  type: "",
  night: "",
  discount: 0,
  total: "",
  date: "",
  time: "",
  notePros: "",
  contact: "",
  items: [],
};

export const camera = (state = initialState, action) => {
  switch (action.type) {
    case CAMERA_WIRE:
      return {
        ...state,
        wire: action.payload,
      };

    case CAMERA_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case CAMERA_NIGHT:
      return {
        ...state,
        night: action.payload,
      };
    case SET_CAMERA_PROVIDERS:
      return {
        ...state,
        providers: [...state.providers, action.payload],
      };
    case MINUS_CAMERA_PROVIDERS:
      return {
        ...state,
        providers: state.providers.filter((item) => item !== action.payload),
      };
    case CAMERA_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case CAMERA_DISCOUNT_TYPE:
      return {
        ...state,
        discount: action.payload,
      };

    case CAMERA_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case CAMERA_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case CAMERA_NOTE_PROS:
      return {
        ...state,
        notePros: action.payload,
      };
    case CAMERA_TOTAL:
      return {
        ...state,
        total: action.payload,
      };

    case ADD_CAMERA_ITEM:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case MINUS_CAMERA_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case ADD_CAMERA_QUANTITY_ITEM:
      const itemAdded = state.items.find((item) => item.id === action.payload);
      const newItemAdded = {
        ...itemAdded,
        quantity: itemAdded.quantity + 1,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload),
          newItemAdded,
        ],
      };

    case MINUS_CAMERA_QUANTITY_ITEM:
      const item = state.items.find((item) => item.id === action.payload);
      const newItem = {
        ...item,
        quantity: item.quantity - 1,
      };
      return {
        ...state,
        items: [
          ...state.items.filter((item) => item.id !== action.payload),
          newItem,
        ],
      };

    case RESET_CAMERA:
      return initialState;
    default:
      return state;
  }
};
