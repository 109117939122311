import {
  FINISH_LAT,
  FINISH_LNG,
  FINISH_MODAL,
  MOVING_DATE,
  MOVING_FINISH,
  MOVING_FINISH_FLOOR_NO,
  MOVING_FINISH_HAS_ELEVATOR,
  MOVING_START,
  MOVING_START_FLOOR_NO,
  MOVING_START_HAS_ELEVATOR,
  MOVING_TIME,
  MOVING_TYPE,
  RESET_MOVING,
  START_LAT,
  START_LNG,
  START_MODAL,
  MOVING_DISCOUNT,
  MOVING_TOTAL,
  MOVING_CONTACT,
  MOVING_NOTE_DRIVER,
  MOVING_NOTE_STUFF,
} from "../constants";

const initialState = {
  start: "",
  startLat: "",
  startLng: "",
  startFloorNo: "",
  startHasElevator: "",
  finish: "",
  finishLat: "",
  finishLng: "",
  finishFloorNo: "",
  finishHasElevator: "",
  typeHome: "",
  typeTruck: "",
  date: "",
  time: "",
  startModal: false,
  finishModal: false,
  discount: "",
  total: "",
  transportPrice: "",
  laborPrice: "",
  contact: "",
  noteDriver: "",
  noteStuff: "",
};

export const moving = (state = initialState, action) => {
  switch (action.type) {
    case MOVING_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case MOVING_NOTE_DRIVER:
      return {
        ...state,
        noteDriver: action.payload,
      };

    case MOVING_NOTE_STUFF:
      return {
        ...state,
        noteStuff: action.payload,
      };
    case MOVING_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case FINISH_MODAL:
      return {
        ...state,
        finishModal: action.payload,
      };
    case MOVING_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      };
    case MOVING_TOTAL:
      return {
        ...state,
        total: action.payload.total,
        transportPrice: action.payload.transportPrice,
        laborPrice: action.payload.laborPrice,
      };

    case FINISH_LAT:
      return {
        ...state,
        finishLat: action.payload,
      };

    case FINISH_LNG:
      return {
        ...state,
        finishLng: action.payload,
      };
    case MOVING_FINISH:
      return {
        ...state,
        finish: action.payload,
      };
    case MOVING_FINISH_FLOOR_NO:
      return {
        ...state,
        finishFloorNo: action.payload,
      };
    case MOVING_FINISH_HAS_ELEVATOR:
      return {
        ...state,
        finishHasElevator: action.payload,
      };
    case START_MODAL:
      return {
        ...state,
        startModal: action.payload,
      };

    case START_LAT:
      return {
        ...state,
        startLat: action.payload,
      };

    case START_LNG:
      return {
        ...state,
        startLng: action.payload,
      };
    case MOVING_START:
      return {
        ...state,
        start: action.payload,
      };
    case MOVING_START_FLOOR_NO:
      return {
        ...state,
        startFloorNo: action.payload,
      };
    case MOVING_START_HAS_ELEVATOR:
      return {
        ...state,
        startHasElevator: action.payload,
      };
    case MOVING_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case MOVING_TYPE:
      return {
        ...state,
        typeHome: action.payload.typeHome,
        typeTruck: action.payload.typeTruck,
      };

    case RESET_MOVING:
      return initialState;
    default:
      return state;
  }
};
