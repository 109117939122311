import "./styles.css";

export const Colors = {
  primary: "#35c073",
  secondary: "#00cc66",
  accent: "#ed3e5b",
  bgExplore: "#fafaf8",
  button: "#fd3752",
  tab: "#333333",
  bgColor: "#F4F1EA",
  orange: "#fe8127",
  green: "#1fb25c",
  blue: "#1c85e8",
  borderActive: "#388bd6",
  border: "#e9e9e9",
  textMain: "#262626",
  textGrey: "#676767",
  hue: "rgba(255, 99, 71, 0.2)",
  greyBg: "#f5f5f5",
  switch: "#4bd160",
  white: "#ffffff",
  transparent: "transparent",
  black: "#000000",
  middleBlack: "#666666",
  bgGrey: "#f7f7f7",
};

const Radio = ({ list, onClick, value, divide }) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {list.map((res) => {
        return (
          <div
            key={res.value}
            onClick={() => onClick(res.value)}
            style={{
              width: divide ? "50%" : "100%",
              marginBottom: 20,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="radio-item">
              <div>
                <div className="radio-item-circle">
                  {value === res.value && (
                    <div className="radio-item-circle-inner" />
                  )}
                </div>
              </div>
              <div className="radio-item-text">{res.name}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Radio;
