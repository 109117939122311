import React from "react";
import styles from "./Notifications.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

function Notifications() {
  return (
    <div className={cx("dashboardContainer")}>
      <div className={styles.content}>
        <h2>Danh sách thông báo</h2>
        {/* Nội dung trang Dashboard */}
        <p>Mọi thông báo dành cho bạn sẽ hiện thị tại đây.</p>
        {/* Bạn có thể thêm các thành phần khác tại đây */}
      </div>
    </div>
  );
}

export default Notifications;
