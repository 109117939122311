import classNames from "classnames/bind";
import { useParams } from "react-router-dom";
import styles from "./NewsDetails.module.scss";

import { itemSliders } from "~/pages/Partners/Solutions";

const cx = classNames.bind(styles);

function NewsDetails() {
  const params = useParams();

  const item = itemSliders.list.find((item) => item.title === params.newsId);

  return (
    <div className={cx("main")}>
      <div className={cx("top")}>
        <h1 className={cx("title")}>{params.newsId}</h1>
      </div>
      <div>
        <img
          alt="image-main"
          src={item.imageUrl}
          className={cx("image-main")}
        />
      </div>
      <div className={cx("content-container")}>
        <div className={cx("content")}>
          <div className={cx("description")}>{item.description}</div>
          <div className={cx("space")} />
          <div className={cx("introDetails")}>{item.introDetails}</div>
          <div className={cx("space")} />

          {item.details.map((item) => (
            <div key={item.id} className={cx("section")}>
              <h3 className={cx("section-title")}>
                {item.id}. {item.title}
              </h3>
              <div className={cx("space")} />

              <ul className={cx("section-list")}>
                {item.content.map((item) => (
                  <li key={item} className={cx("section-item")}>
                    {item} <div className={cx("space")} />
                  </li>
                ))}
              </ul>

              {item.imageUrl && (
                <div>
                  <img src={item.imageUrl} className={cx("image-section")} />
                  <div className={cx("space")} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;

export const data = [
  {
    id: 1,
    imageUrl:
      "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1617737158/shutterstock_720994654/shutterstock_720994654.jpg?_i=AA",
    title: "Create the Ultimate Home Office Setup in 5 Steps",
    description: "ưtgeargdsfgdsfg",
  },
  {
    id: 2,
    imageUrl:
      "https://res.cloudinary.com/updater-marketing/images/w_1024,h_683/f_auto,q_auto/v1603519436/calculator-desk_how-to-save-on-cable-internet-and-phone/calculator-desk_how-to-save-on-cable-internet-and-phone.jpg?_i=AA",
    title: "How to Save on Internet, Cable, and Phone",
    description:
      "Moving is expensive and knowing how to save on your internet, cable, and phone bills can really help balance out that spending. Curious about the best ways to save? Turns out, it’s not such a challenge once you know how. Here are 11 of our tried-and-true techniques for how you can save on internet, cable, and phone while still getting the most out of your monthly payments.",
  },
  {
    id: 3,
    imageUrl:
      "https://res.cloudinary.com/updater-marketing/images/f_auto,q_auto/v1619369585/family-sitting-on-sofa-i-1/family-sitting-on-sofa-i-1.jpg?_i=AA",
    title: "Moving While Expecting Kids? Be Sure to Set up TV and Internet",
    description:
      "Of all the things you deal with as a new parent during a household move, your belongings may be the most obvious. But what about services? When it comes to TV and internet, it pays to plan ahead and make sure you have service for every step of your journey. Here’s how to ensure you’re prepared to tackle parenthood, especially in your new home.",
  },
];
