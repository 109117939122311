import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./MovingSteps.module.scss";

import SeventhStep from "./SeventhStep";

import SmallLayout from "~/components/SmallLayout";
import ListInternet from "./ListInternet";

const cx = classNames.bind(styles);

function InternetPackages() {
  const [info, setInfo] = useState({
    address: "",
    type: "",
    numberOfDevices: "",
    purposeOfInternet: [],
    purposeOfTV: [],
    phoneNumber: "",
    note: "",
  });

  return (
    <SmallLayout>
      <div className={cx("main")}>
        <div className={cx("content")}>
          <h2 className={cx("heading")}>Danh sách các gói mạng có sẵn</h2>
          <ListInternet />
          <SeventhStep info={info} setInfo={setInfo} />
        </div>
      </div>
    </SmallLayout>
  );
}

export default InternetPackages;
